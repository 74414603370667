import React, { useRef, useContext } from 'react';
import { useRow, useTimelineContext } from 'dnd-timeline';
import { emitCustomEvent } from 'react-custom-events';
import { Box, Text } from '@chakra-ui/react';
import useScrubberHandlers from 'hooks/useScrubberHandlers';

function Row(props) {
  const {
    setNodeRef,
    setSidebarRef,
    rowWrapperStyle,
    rowStyle,
    rowSidebarStyle,
  } = useRow({ id: props.id });
  const timeCursorRef = useRef(null);
  const { handleOnSeekUpdate } = useScrubberHandlers();
  const { sidebarWidth, pixelsToValue } = useTimelineContext();

  const handleMouseDown = (e) => {
    // Check if the click happened on the row wrapper
    if (!timeCursorRef.current || !timeCursorRef.current.contains(e.target)) {
      return;
    }
    // Check if left click
    if (e.button !== 0) {
      return;
    }
    // Check if the click is not on a specific element (e.g., 'btn-newpage')
    if (e.target.closest('[data-id="btn-newpage"]')) {
      return;
    }

    if (e.target.closest('[data-testid="delete-gap"]')) {
      return;
    }

    if (timeCursorRef.current) {
      const div = timeCursorRef.current;
      const rect = div.getBoundingClientRect();
      const clickX = e.clientX - rect.left; // X position within the element
      let newPosition = clickX - sidebarWidth; // Adjust based on your layout
      newPosition = Math.max(0, Math.min(newPosition, rect.width));
      const newTime = pixelsToValue(newPosition); // Use pixelToValue to convert position to time
      handleOnSeekUpdate(newTime / 1000);
    }
  };

  return (
    <Box
      borderBottom={1}
      width="100%"
      style={{
        ...rowWrapperStyle,
        minHeight: 35,
      }}
      sx={{
        backgroundImage: 'shark',
        backgroundColor: 'shark',
        '&:nth-of-type(odd)': {
          backgroundColor: 'darkShark',
        },
      }}
      ref={timeCursorRef}
      as="button"
      onMouseUp={handleMouseDown}
      onPointerUp={handleMouseDown}
      onTouchEnd={handleMouseDown}
      data-testid="timeline-row"
    >
      <div ref={setSidebarRef} style={rowSidebarStyle}>
        {props.sidebar}
      </div>
      <div ref={setNodeRef} style={rowStyle}>
        {props.children}
      </div>
    </Box>
  );
}

export default Row;

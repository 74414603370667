import { Flex, Icon, IconButton } from '@chakra-ui/react';
import { BsEyeSlashFill } from 'react-icons/bs';
import React, { useEffect, useRef, useState } from 'react';
import {
  HiVolumeUp,
  HiVolumeOff,
  HiEye,
  HiEyeOff,
  HiLockClosed,
  HiLockOpen,
} from 'react-icons/hi';
import {
  GsapPixieContext,
  Events,
  emitCustomEvent,
  useCustomEventListener,
} from 'reacthub-pixitweenjs';
import { nanoid } from 'nanoid';
import useDebounce from 'hooks/useDebounce';
import ScrubberRowAction from './SidebarAction';

function Sidebar(props) {
  //// const { scale, scaleSplitCount, scaleWidth, startLeft } = scrubberBarMeta;
  const [collision, setCollision] = React.useState({});
  const [data, setData] = useState([]);
  const timelineScrubberRef = useRef(null);
  ////const dimensions = useRefDimensions(timelineScrubberRef);
  ///const { width: dimWidth } = dimensions || {};
  const timelineStateRef = useRef(null);
  const rowMenusRef = useRef(null);
  const timelineEngineTimeRef = useRef(0.0);
  const autoScrollWhenPlay = useRef(true);
  const { playerTimeRef, tl: timeline } = React.useContext(GsapPixieContext);
  const rafRef = React.useRef();
  const seekBarDraggedDurationRef = React.useRef(0);

  const handleOnLayerLock = (item) => {
    console.log('handleOnLayerLock', item);
  };

  const updateVideoPlayerState = (state) => {
    console.log('updateVideoPlayerState', state);
  };

  const handleOnLayerVisible = (item) => {
    console.log('handleOnLayerVisible', item);
  };

  const handleOnLayerMute = (item) => {
    console.log('handleOnLayerMute', item);
  };

  const item = props?.item || {};
  const isVideoOrAudio = item?.type === 'video' || item?.type === 'audio';
  const isAudio = item?.type === 'audio';

  return (
    <Flex
      borderRight="1px"
      width="9rem"
      display="flex"
      flexDirection="row"
      alignItems="center"
      paddingLeft="3"
    >
      <Flex
        height="32px"
        padding="2px"
        className="timeline-list-item"
        key={props.id}
        justifyContent="flex-start"
        alignItems="center"
      >
        <ScrubberRowAction
          onClick={() => {
            emitCustomEvent(Events.SCRUBBER_PAUSE);
            updateVideoPlayerState({
              play: false,
              playedSeconds: playerTimeRef.current,
            });
            handleOnLayerLock({ ...props?.item, locked: !props?.item?.locked });
          }}
          active={props?.item?.locked}
          icon={props?.item?.locked ? HiLockClosed : HiLockOpen}
          title="lock/unlock"
        />
        {!isAudio && (
          <ScrubberRowAction
            onClick={() => {
              emitCustomEvent(Events.SCRUBBER_PAUSE);
              updateVideoPlayerState({
                play: false,
                playedSeconds: playerTimeRef.current,
              });
              handleOnLayerVisible({ ...item, visible: !item.visible });
            }}
            active={!item.visible}
            icon={!item.visible ? HiEyeOff : HiEye}
            title="hide/show"
          />
        )}
        {isVideoOrAudio && (
          <ScrubberRowAction
            onClick={() => {
              emitCustomEvent(Events.SCRUBBER_PAUSE);
              updateVideoPlayerState({
                play: false,
                playedSeconds: playerTimeRef.current,
              });
              handleOnLayerMute({ ...item, mute: !item.mute });
            }}
            active={item.mute}
            icon={item.mute ? HiVolumeOff : HiVolumeUp}
            title="mute/unmute"
          />
        )}
      </Flex>
    </Flex>
  );
}

export default Sidebar;

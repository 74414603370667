import { useContext } from 'react';
import {
  useEditorObservable,
  useEditAreaObservable,
  useUserProjectObservable,
  useCanvasZoomObservable,
} from 'observeables';
import { reducerNames } from '../constants';
import { MILLISECOND_SEPERATOR, EditorApiConstants } from '../constants';
import { isEmpty, times, debounce, get, omit, find } from 'lodash';
import { nanoid } from 'nanoid';
import { EditorApiContext } from 'providers/api-provider';
import { toISOStringWithTimezone } from 'utils/unixtimeStamp';

// immutablility
// https://gist.github.com/newtriks/a223b80baa3dd8765713

export const useEditorRepository = () => {
  const editorObserveable = useEditorObservable();
  const editAreaObserveable = useEditAreaObservable();
  const userProjectObserveable = useUserProjectObservable();
  const canvasZoomObserveable = useCanvasZoomObservable();

  /** Axios client */
  const _axios = useContext(EditorApiContext);

  const remoteFetch = async (id) => {
    try {
      editorObserveable.loading(true);
      const editorRemoteData = await _axios
        .get(`/${EditorApiConstants.endpoints.EDITOR_PROJECT_ASSET}/${id}`)
        .then(({ data }) => {
          return data?.data;
        });

      const { width, height } = get(editorRemoteData, 'data', []);
      const initialData = {
        title: editorRemoteData?.title,
        clips: editorRemoteData.data?.clips,
        projectid: editorRemoteData.projectid,
        user: editorRemoteData.user,
        width,
        height,
        defaults: editorRemoteData.data?.defaults,
      };
      editorObserveable.initialData(initialData);
    } catch (error) {
      editorObserveable.error(error?.message);
    } finally {
      editorObserveable.loading(false);
    }
  };

  const remoteUpdate = async () => {
    try {
      const userProjData = userProjectObserveable.getObservable().getValue();
      const editData = editorObserveable.getObservable().getValue();
      const title = get(editData, 'title', 'Flex Pod');

      const postBody = {
        id: userProjData.projectId,
        projectid: userProjData.projectId,
        user: userProjData.email,
        title,
        data: editData,
        updatedAt: toISOStringWithTimezone(new Date()),
        status: 'ACTIVE',
      };

      await _axios
        .post(
          `/${EditorApiConstants.endpoints.EDITOR_PROJECT_ASSET}`,
          JSON.stringify(postBody),
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(({ data }) => {
          return data;
        });
    } catch (error) {
      editorObserveable.error(error?.message);
    } finally {
      //// editorObserveable.loading(false);
    }
  };

  const debounceRemoteUpdateFn = debounce(remoteUpdate, 1500);

  const updateProjectIds = async (payload) => {
    try {
      userProjectObserveable.update(payload);
    } catch (error) {
      console.log('update error in editor state');
    } finally {
    }
  };

  const update = async (payload) => {
    try {
      editorObserveable.update(payload);
      setTimeout(async () => {
        await debounceRemoteUpdateFn();
      }, 1);
    } catch (error) {
      console.log('update error in editor state');
    } finally {
    }
  };

  const updateItemByDeletingGap = async (payload) => {
    try {
      editorObserveable.updateItemByDeletingGap(payload);
      setTimeout(async () => {
        await debounceRemoteUpdateFn();
      }, 1);
    } catch (error) {
      console.log('update error in editor state');
    } finally {
    }
  };

  const bulkUpdate = async (payload = []) => {
    try {
      editorObserveable.bulkUpdate(payload);
      setTimeout(async () => {
        await debounceRemoteUpdateFn();
      }, 1);
    } catch (error) {
      console.log('update error in editor state');
    } finally {
    }
  };

  const updateScrubberRow = async (payload) => {
    try {
      editorObserveable.updateScrubberRow(payload);
      setTimeout(async () => {
        await debounceRemoteUpdateFn();
      }, 1);
    } catch (error) {
      console.log('update error in editor state');
    } finally {
    }
  };

  const updateLayoutSize = async (payload) => {
    try {
      editorObserveable.updateLayoutSize(payload);
      setTimeout(async () => {
        await debounceRemoteUpdateFn();
      }, 1);
    } catch (error) {
      console.log('update error in editor state');
    } finally {
    }
  };

  const updateTitle = async (title) => {
    try {
      editorObserveable.updateTitle(title);
      setTimeout(async () => {
        await debounceRemoteUpdateFn();
      }, 1);
    } catch (error) {
      console.log('update error in editor state');
    } finally {
    }
  };

  const updateActionItemsTimes = async (payload) => {
    try {
      editorObserveable.updateActionItemsTimes(payload);
      setTimeout(async () => {
        await debounceRemoteUpdateFn();
      }, 1);
    } catch (error) {
      console.log('update error in editor state');
    } finally {
    }
  };

  const add = async (payload) => {
    try {
      const milliSeconds = 1000;
      const totalVideoSeconds = editorObserveable.getTotalSeconds();
      const { minRow = 1, maxRow = 1 } =
        editorObserveable.getScrubberMinMaxRowIndex();

      const name = payload.droptype;
      const { zoomValue } = canvasZoomObserveable.getObservable().getValue();
      const layoutSize = editorObserveable.getLayoutSize();
      const { width: zoomWidth, height: zoomHeight } = layoutSize;

      /** Todo replace edit area width and height */
      let fontStyle = payload?.fontStyle;
      let boxStyles = payload?.boxStyles;
      let textValue = payload?.textValue;
      let boxwidth = String(boxStyles?.width?.replace('px', ''));
      let boxheight = String(boxStyles?.height?.replace('px', ''));
      let heading = payload?.heading;
      let framesToAdd = 0;
      let videoEndTime = 1000; // one second
      let s3ThumbUrl = '';
      let s3Url = payload?.s3Url || '';
      let s3Key = payload?.s3Key || '';
      let transformation = payload?.transformation || {};
      const { isUnSplash } = payload;
      if (
        [reducerNames.shapes, reducerNames.emojis, reducerNames.gifs].includes(
          name
        )
      ) {
        textValue = payload?.name;
      }

      transformation = {
        ...transformation,
        x: zoomWidth / 2,
        y: zoomHeight / 2,
        width: get(transformation, 'width', 300),
        height: get(transformation, 'height', 300),
        rotate: 0,
        scale: [1, 1],
        zIndex: maxRow + 1,
        toCenter: true,
        effect: 'none',
        animation: 'NONE',
        stroke: null,
        strokeWidth: 0,
        opacity: 1,
        shadowEnabled: false,
        blurEnabled: false,
        shadowColor: null,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        shadowBlur: 0,
        colorCorrection: {
          reset: false,
        },
        blurRadius: 0,
      };

      if (name === reducerNames.titles) {
        transformation = {
          ...transformation,
          width: get(transformation, 'width', 300),
          height: get(transformation, 'height', 300),
          lineHeight: get(transformation, 'lineHeight', 35),
          fontSize: get(transformation, 'fontSize', 36),
          // translate: [tranX, tranY],
        };
      } else if (name === reducerNames.images || name === reducerNames.shapes) {
        const imageWidth = get(transformation, 'width', 300);
        const imageHeight = get(transformation, 'height', 300);
        const canvasAspectRatio = zoomWidth / zoomHeight;
        const imageAspectRatio = imageWidth / imageHeight;
        let renderWidth, renderHeight;
        if (imageWidth > zoomWidth || imageHeight > zoomHeight) {
          if (canvasAspectRatio > imageAspectRatio) {
            renderWidth = zoomHeight * imageAspectRatio;
            renderHeight = zoomHeight;
          } else {
            renderWidth = zoomWidth;
            renderHeight = zoomWidth / imageAspectRatio;
          }
        } else {
          renderWidth = imageWidth;
          renderHeight = imageHeight;
        }

        // Calculate the position to center the image on the canvas
        transformation = {
          ...transformation,
          x: zoomWidth / 2,
          y: zoomHeight / 2,
          width: Number(Math.round(renderWidth)),
          height: Number(Math.round(renderHeight)),
        };
      }

      if (name === reducerNames.videos) {
        videoEndTime = payload.end;
        s3ThumbUrl = payload.s3ThumbUrl;
        s3Key = payload.s3Key;
        s3Url = payload.s3Url;
        framesToAdd = Math.ceil(payload.duration / 10);

        const imageWidth = get(transformation, 'width', 300);
        const imageHeight = get(transformation, 'height', 300);
        const canvasAspectRatio = zoomWidth / zoomHeight;
        const imageAspectRatio = imageWidth / imageHeight;
        let renderWidth, renderHeight;
        if (imageWidth > zoomWidth || imageHeight > zoomHeight) {
          if (canvasAspectRatio > imageAspectRatio) {
            renderWidth = zoomHeight * imageAspectRatio;
            renderHeight = zoomHeight;
          } else {
            renderWidth = zoomWidth;
            renderHeight = zoomWidth / imageAspectRatio;
          }
        } else {
          renderWidth = imageWidth;
          renderHeight = imageHeight;
        }
        transformation = {
          ...transformation,
          x: zoomWidth / 2,
          y: zoomHeight / 2,
          width: renderWidth,
          height: renderHeight,
        };
        if (totalVideoSeconds <= 0) {
          transformation = {
            ...transformation,
            x: Math.round(payload.width / 2),
            y: Math.round(payload.height / 2),
          };

          /// reset the layout size
          editorObserveable.updateLayoutSize({
            size: `${payload.width} X ${payload.height}`,
          });
        }
      }

      // const videoStart = 0;
      // ///  totalVideoSeconds > 0 ? totalVideoSeconds * milliSeconds : 0;
      // const videoEnd = videoEndTime;
      // totalVideoSeconds > 0
      //   ? totalVideoSeconds * milliSeconds + videoEndTime
      //   : videoEndTime;

      const newPayload = {
        ...payload,
        isAudio: name === 'audios',
        isVideo: name === 'videos',
        transformation,
        textValue,
        ...(name === 'titles' && {
          fontStyle: {
            ...fontStyle,
            fontSize: heading ? fontStyle.fontSize : 50,
            lineHeight: 45,
          },
        }),
        ...(name === 'videos' && {
          // start: videoStart,
          // end: videoEnd,
          frames: times(framesToAdd, () => s3ThumbUrl),
          isAudioDetached: false,
          s3ThumbUrl,
          s3Key,
          s3Url,
        }), // update only for videos
        scrubberRow: maxRow + 1,
      };
      console.log('add -> newPayload', newPayload);
      editorObserveable.add(newPayload);
      setTimeout(async () => {
        await debounceRemoteUpdateFn();
      }, 1);
    } catch (error) {
      editorObserveable.error('Error in updating editorstate');
    } finally {
      // editorObserveable.updating(false);
    }
  };

  const updateTransformation = async (payload) => {
    const {
      uniqueId,
      animation,
      colorCorrection,
      overlay,
      strokeColor,
      strokeWidth,
      cornerRadius,
      rotate,
      rotation,
      scale,
      width,
      height,
      x,
      y,
      blurRadius,
      blurEnabled,
      stroke,
      lineHeight,
      letterSpacing,
      textDecoration,
      fontWeight,
      fontStyle,
      fontSize,
      fontFamily,
      align,
      verticalAlign,
      zIndex,
      effect,
      wordWrapWidth,
      leading,
      color,
      waveform,
    } = payload;
    const clips = editorObserveable.getObservable().getValue();
    /// Find the uniqueId;
    const selectedClip = find(clips.clips, (clip) => {
      const findTheItem = find(clip.items, (f) => f.uniqueId === uniqueId);
      return !!findTheItem;
    });

    const selectedItem = find(
      selectedClip.items,
      (f) => f.uniqueId === uniqueId
    );

    if (isEmpty(selectedItem)) return null;
    const updatedPayload = {
      ...selectedItem,
      transformation: {
        ...selectedItem.transformation,
        ...(animation && { animation }),
        ...(colorCorrection && { colorCorrection }),
        ...(overlay && { overlay }),
        ...(strokeColor && { strokeColor }),
        ...((strokeWidth || strokeWidth === 0) && { strokeWidth }),
        ...((cornerRadius || cornerRadius === 0) && { cornerRadius }),
        ...((rotate || rotate === 0) && { rotate }),
        ...((rotation || rotation === 0) && { rotate: rotation }),
        ...(scale && { scale }),
        ...(width && { width }),
        ...(height && { height }),
        ...((x || x === 0) && { x }),
        ...((y || y === 0) && { y }),
        ...((blurRadius || blurRadius === 0) && { blurRadius }),
        ...(blurEnabled && { blurEnabled }),
        ...(stroke && { stroke }),
        ...(lineHeight && { lineHeight }),
        ...(letterSpacing && { letterSpacing }),
        ...(textDecoration && { textDecoration }),
        ...(fontWeight && { fontWeight }),
        ...(fontStyle && { fontStyle }),
        ...(fontSize && { fontSize }),
        ...(fontFamily && { fontFamily }),
        ...(align && { align }),
        ...(verticalAlign && { verticalAlign }),
        ...((zIndex || zIndex === 0 || zIndex < 0) && { zIndex }),
        ...(effect && { effect }),
        ...(wordWrapWidth && { wordWrapWidth }),
        ...(leading && { leading }),
        ...(color && { color }),
        ...(waveform && { waveform }),
      },
      ...omit(selectedClip, ['items', 'id', 'duration']),
    };
    editorObserveable.update(updatedPayload);
    await debounceRemoteUpdateFn();
  };

  const splitVideo = (payload) => {
    const { uniqueId, playedSeconds, progress = 0 } = payload;
    // get played seconds to split the video
    // const { playedSeconds } = playerObserveable.getObservable().getValue();
    /** Selected video frame */
    if (!playedSeconds || playedSeconds <= 0) {
      return;
    }

    const clips = editorObserveable.getObservable().getValue();
    /// Find the uniqueId;
    const selectedClip = find(clips.clips, (clip) => {
      const findTheItem = find(clip.items, (f) => f.uniqueId === uniqueId);
      return !!findTheItem;
    });

    const selectedItem = find(
      selectedClip.items,
      (f) => f.uniqueId === uniqueId
    );

    if (isEmpty(selectedItem)) return null;
    const millisecond = playedSeconds * 1000;
    const splitAtMilliSecond = playedSeconds * 1000;
    if (isEmpty(selectedItem)) return null;
    const { droptype } = selectedItem;

    const closestValueInMilliSeconds =
      Math.round(millisecond / MILLISECOND_SEPERATOR) * MILLISECOND_SEPERATOR;
    const newUniqueID = nanoid(10);

    const isMedia =
      droptype === reducerNames.videos ||
      droptype === reducerNames.audios ||
      droptype === reducerNames.gifs;

    const startMs = isMedia
      ? selectedItem.frameStartAt + (splitAtMilliSecond - selectedItem.start)
      : splitAtMilliSecond;
    const endMs = isMedia ? selectedItem.frameEndAt : selectedItem.end;

    const newItem = {
      ...selectedItem,
      uniqueId: newUniqueID,
      id: newUniqueID,
      start: splitAtMilliSecond,
      frameStartAt: startMs,
      frames: [],
      transitions: [],
      ...omit(selectedClip, ['items', 'id', 'duration']),
    };

    const updatedItem = {
      ...selectedItem,
      end: splitAtMilliSecond,
      frameEndAt: startMs,
      frames: [],
      ...omit(selectedClip, ['items', 'id', 'duration']),
    };

    // step 1
    update(updatedItem);

    // step 2
    editorObserveable.add(newItem);
  };

  const redo = async () => {
    try {
      if (editorObserveable.canRedo()) {
        editorObserveable.redo();
      }
    } catch (error) {
      editorObserveable.error('Error in updating editorstate');
    } finally {
    }
  };

  const undo = async () => {
    try {
      if (editorObserveable.canUndo()) {
        editorObserveable.undo();
      }
    } catch (error) {
      editorObserveable.error('Error in updating editorstate');
    } finally {
    }
  };

  const remove = async (id) => {
    try {
      editorObserveable.remove(id);
      await debounceRemoteUpdateFn();
    } catch (error) {
      editorObserveable.error('Error in updating editorstate');
    } finally {
    }
  };

  const updateVisible = async (payload) => {
    try {
      editorObserveable.updateVisible(payload);
      await debounceRemoteUpdateFn();
    } catch (error) {
      editorObserveable.error('Error in updating editorstate');
    } finally {
    }
  };

  const updateMute = async (payload) => {
    try {
      editorObserveable.updateMute(payload);
      await debounceRemoteUpdateFn();
    } catch (error) {
      editorObserveable.error('Error in updating editorstate');
    } finally {
    }
  };

  const updateLocked = async (payload) => {
    try {
      editorObserveable.updateLocked(payload);
      await debounceRemoteUpdateFn();
    } catch (error) {
      editorObserveable.error('Error in updating editorstate');
    } finally {
    }
  };

  const updateCoverPhoto = async (payload) => {
    try {
      editorObserveable.updateCoverPhoto(payload);
      await debounceRemoteUpdateFn();
    } catch (error) {
      editorObserveable.error('Error in updating editorstate');
    } finally {
    }
  };

  const getEditorObservable = () => editorObserveable.getObservable();
  const getTotalSeconds = () => editorObserveable.getTotalSeconds();
  const getScrubberMinMaxRowIndex = () =>
    editorObserveable.getScrubberMinMaxRowIndex();
  return {
    update,
    updateItemByDeletingGap,
    updateScrubberRow,
    updateActionItemsTimes,
    updateLayoutSize,
    add,
    splitVideo,
    updateTransformation,
    redo,
    undo,
    remove,
    getEditorObservable,
    getTotalSeconds,
    getScrubberMinMaxRowIndex,
    remoteFetch,
    bulkUpdate,
    updateVisible,
    updateMute,
    updateLocked,
    updateTitle,
    remoteUpdate,
    updateCoverPhoto,
  };
};

import { useEffect, useState, useRef } from 'react';
import { editorSubject } from 'observeables/editorObserveable';
import { distinctUntilChanged, map } from 'rxjs';
import isEqual from 'react-fast-compare';
import transform from 'lodash/transform';
import flatMap from 'lodash/flatMap';
import mapLodash from 'lodash/map';
import orderBy from 'lodash/orderBy';

export const useEditorStateRepo = () => {
  const [state, setState] = useState({});
  const stateRef = useRef({}); // Store latest state to prevent unnecessary updates

  useEffect(() => {
    const subscription = editorSubject
      .pipe(
        map((currentState) => {
          const clipItems = currentState?.clips || [];
          const clipItemData = orderBy(
            flatMap(clipItems, (clip) =>
              mapLodash(clip.items, (item) => ({
                ...item,
                scrubberRow: Number(clip.scrubberRow),
                mute: clip.mute,
                locked: clip.locked,
                visible: clip.visible,
                clipId: clip.id,
              }))
            ),
            ['scrubberRow', 'startSeconds'],
            ['asc', 'asc']
          );

          // Create a normalized state object with key-value pairs
          return transform(
            clipItemData,
            (result, value) => {
              result[value.uniqueId] = value;
            },
            {}
          );
        }),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)) // Prevent updates if state is the same
      )
      .subscribe((normalizedState) => {
        if (!isEqual(stateRef.current, normalizedState)) {
          stateRef.current = normalizedState; // Update ref before state
          setState(normalizedState);
        }
      });

    return () => subscription.unsubscribe();
  }, []);

  return state;
};

import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  ButtonGroup,
  Center,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  useStyleConfig,
} from '@chakra-ui/react';
import { Redo, Undo } from 'assets/icons';
import logo from 'assets/images/clippy-logo.svg';
import { InputExtended, ExportButton } from 'components';
import { canvasSize } from 'constants';
import { get, isEmpty, set } from 'lodash';
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AiFillSave } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import HeaderUserMenu from './header-user-menu';
import { useEditorRepository, usePollingRepository } from 'repositories';
import { useScrubberData } from 'hooks/useScrubberData';
import { useObservableWithPipe } from 'utils/useObserveable';
import {
  useScheduleVideoGeneration,
  useGetRemotionProgress,
} from 'api/useVideoTranscodeApi';
import { toast } from 'react-toastify';

const Header = () => {
  const { sortedScrubberData, totalVideoSeconds, projectTitle, width, height } =
    useScrubberData();
  const pollingRepo = usePollingRepository();

  const styles = useStyleConfig('Select', {});
  const { id } = useParams();
  const [customSize, setCustomSize] = React.useState({
    width: '100',
    height: '100',
  });

  // state
  /** state */
  const [isVideoInProgress, setIsVideoInProgress] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [videoExportStatusInProgress, setVideoExportStatusInProgress] =
    React.useState(false);

  // oAuth
  const { user } = useAuth0();

  // tanstack query
  const {
    mutateAsync: vidGenTrigger,
    isLoading: vidGenIsLoading,
    isError: vidGenIsError,
    data: vidGenData,
    isSuccess: vidGenIsSuccess,
  } = useScheduleVideoGeneration();
  const { mutateAsync: getRemotionProgress } = useGetRemotionProgress();

  // end tanstack

  // selectors
  // const { isInProgress } = useSelector((state) => state.videoExport);
  const compositionData = sortedScrubberData;
  const editorRepository = useEditorRepository();

  // effects
  React.useEffect(() => {
    if (videoExportStatusInProgress) {
      if (vidGenIsError) {
        toast('Video export failed. Check queue for completion status.');
        setVideoExportStatusInProgress(false);
      } else if (vidGenIsSuccess && vidGenData) {
        // update polling repo.
        pollingRepo.update({
          interval: 5000,
          toggled: true,
          lambdaInfo: vidGenData,
          isInProgress: true,
        });
        setVideoExportStatusInProgress(false);
      }
    }
  }, [
    videoExportStatusInProgress,
    vidGenData,
    pollingRepo,
    vidGenIsSuccess,
    vidGenIsLoading,
    vidGenIsError,
  ]);

  const handleVideoExportOutput = async (formdata) => {
    setVideoExportStatusInProgress(true);
    vidGenTrigger({
      title: formdata?.filename || projectTitle,
      size: { width, height, fps: Number(formdata?.frameRate) || 30 },
      totalDuration: totalVideoSeconds,
      compositionData,
      projectId: id,
      userId: user.email,
    });
    toast('Video export started. Check queue for completion status.');
  };

  const updateProjectName = React.useCallback(
    (value) => {
      editorRepository.updateTitle(value);
    },
    [editorRepository]
  );

  const handleOnZoomChange = (v) => {
    const zoom = v.split('X');
    const zoomWidth = zoom[0].replace(/\D/g, '');
    const zoomHeight = zoom[1].replace(/\D/g, '');
    const size = canvasSize.find((f) => f.value === v) || {
      width: zoomWidth,
      height: zoomHeight,
    };
    editorRepository.updateLayoutSize({
      size: v,
      sizer: size,
    });
  };

  const handleOnGoHome = () => {
    window.location.replace(`https://www.clippy.io/dashboard`);
  };

  return (
    <Flex
      px={[2, 2, 2, 2, 10]}
      h={'4.5rem'}
      alignItems={'center'}
      justifyContent={'space-between'}
      data-testid="headerflex"
    >
      <Flex>
        <Menu bgColor="shark">
          <MenuButton
            as={Button}
            aria-label="Options"
            variant="outline"
            _hover={{
              backgroundColor: 'charade',
            }}
            _activeLink={{
              backgroundColor: 'charade',
              fontWeight: '700',
            }}
            border="1px solid shark"
          >
            <Image src={logo} />
          </MenuButton>
          <MenuList bgColor="shark" fontSize="small">
            <MenuItem
              bgColor="shark"
              _hover={{ backgroundColor: '#17161D' }}
              _active={{ backgroundColor: '#17161D' }}
              _focus={{ backgroundColor: '#17161D' }}
              onClick={handleOnGoHome}
            >
              Back to Home
            </MenuItem>
            <MenuDivider />
            {/* <MenuItem>File</MenuItem>
            <MenuItem>Edit</MenuItem>
            <MenuItem>View</MenuItem>
            <MenuItem>Project Settings</MenuItem> */}
            <MenuItem
              bgColor="shark"
              _hover={{ backgroundColor: '#17161D' }}
              _active={{ backgroundColor: '#17161D' }}
              _focus={{ backgroundColor: '#17161D' }}
              // onClick={handleOnSaveProject}
            >
              Save
            </MenuItem>
            {/* <MenuItem>Save to folder</MenuItem>
            <MenuItem>Version history</MenuItem>
            <MenuItem>Make a copy</MenuItem>
            <MenuItem>Export</MenuItem> */}
            {/* <MenuDivider />
            <MenuItem>Share</MenuItem>
            <MenuDivider />
            <MenuItem>Plugins</MenuItem>
            <MenuDivider />
            <MenuItem>Help</MenuItem> */}
          </MenuList>
        </Menu>
      </Flex>
      <Flex>
        {/* <Text fontSize="sm">Eyecast Flex Max Pro</Text> */}
        <Editable
          textAlign="center"
          value={projectTitle}
          onChange={(val) => updateProjectName(val)}
          _hover={{
            border: '1px solid turquoise',
            borderRadius: 5,
            transition: 'box-shadow .3s',
            boxShadow: '0 0 11px rgba(33,33,33,.2)',
          }}
        >
          <EditablePreview padding={3} />
          <EditableInput />
        </Editable>
      </Flex>
      <Flex>
        {false && (
          <Center flexDir="row">
            <IconButton
              variant="solid"
              bgColor="woodsmoke2"
              _hover={{ background: 'tuna' }}
              icon={<Icon as={Undo} w={6} h={'18px'} color="white" />}
            />
            <IconButton
              variant="solid"
              bgColor="woodsmoke2"
              _hover={{ background: 'tuna' }}
              icon={<Icon as={Redo} w={6} h={'18px'} color="white" />}
            />
          </Center>
        )}
        <HeaderUserMenu />
        <ButtonGroup alignItems="center">
          {false && (
            <Button
              variant="solid"
              bgColor="shark"
              w={'74px'}
              h={'36px'}
              fontSize="12px"
              fontWeight="500"
              color="silver"
              _hover={{ background: 'tuna' }}
            >
              Share
            </Button>
          )}
          <ExportButton
            name={projectTitle}
            onExport={handleVideoExportOutput}
            isVideoInProgress={isVideoInProgress}
            videoExportStatusInProgress={videoExportStatusInProgress}
            progress={progress}
          />
        </ButtonGroup>
        <Center flexDir="row" ml={2} maxW={100}>
          <Menu closeOnSelect={false}>
            <MenuButton
              as={Button}
              colorScheme="charade"
              variant="outline"
              fontSize="xs"
              p={2}
            >
              {`${width} X ${height}`} <ChevronDownIcon />
            </MenuButton>
            <MenuList minWidth="100px" bgColor="charade">
              <MenuOptionGroup
                title="Pre Defined"
                type="radio"
                bgColor="charade"
              >
                <MenuItemOption value="asc" style={styles.option}>
                  <HStack>
                    <InputExtended
                      placeholder="Width"
                      width="100px"
                      size="sm"
                      value={customSize.width}
                      onChange={(e) => {
                        console.log('Width', e);
                        setCustomSize({ ...customSize, width: e.target.value });
                      }}
                    />
                    <InputExtended
                      placeholder="Height"
                      width="100px"
                      size="sm"
                      value={customSize.height}
                      onChange={(e) => {
                        setCustomSize({
                          ...customSize,
                          height: e.target.value,
                        });
                      }}
                    />
                    <IconButton
                      as={'div'}
                      colorScheme="blue"
                      aria-label="Save"
                      title="Save"
                      onClick={() => {
                        handleOnZoomChange(
                          `${customSize.width} X ${customSize.height}`
                        );
                      }}
                      icon={<AiFillSave />}
                    />
                  </HStack>
                </MenuItemOption>
                <MenuDivider />
                {canvasSize.map((csize) => (
                  <MenuItemOption
                    key={csize.name}
                    value={csize.value}
                    style={styles.option}
                    onClick={() => handleOnZoomChange(csize.value)}
                  >
                    {csize.name}
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        </Center>
        {/* <Center flexDir="row">
          <Select
            variant="solid"
            value={`${zoomValue} px`}
            placeholder={`${zoomValue} px`}
            bgColor="charade"
            ml={2}
            size="sm"
            color="white"
            width={200}
            fontSize="md"
            onChange={handleOnZoomChange}
            _active={{
              background: 'charade',
              bgColor: 'charade',
            }}
          >
            {canvasSize.map((csize) => (
              <option
                key={csize.name}
                value={csize.value}
                style={styles.option}
              >
                {csize.name}
              </option>
            ))}
          </Select>
        </Center> */}
      </Flex>
    </Flex>
  );
};

export default Header;

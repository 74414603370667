import { Center, VStack } from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useCreateProjectDetails } from 'api/useUserProjectApi';
import { LoadingSpinner } from 'components';
import { useUserProjectRepository } from 'repositories';
import spinnerAnimationData from '../../assets/images/clippy-spinner.json';

export const EditorHome = () => {
  const { id } = useParams();
  const [createNewProject, setCreateNewProject] = React.useState(false);
  const history = useHistory();
  const { data, mutateAsync, isLoading, isSuccess } = useCreateProjectDetails();
  const userRepo = useUserProjectRepository();

  /** Create Project ID if not exists */
  React.useEffect(() => {
    if (!id) {
      setCreateNewProject(true);
      mutateAsync({
        data: {
          title: 'My Project Title',
          size: { height: 1080, width: 1920 },
          totalDuration: 0,
          compositionData: {},
        },
      });
    } else {
      history.replace(`/canvas/${id}`);
    }
  }, [history, id, mutateAsync]);

  React.useEffect(() => {
    if (!id) {
      if (!isLoading && isSuccess) {
        if (createNewProject) {
          console.log('data', data);
          userRepo.updateProjectIds({
            projectId: data._id,
            projectName: data.title,
          });
        }
        history.replace(`/canvas/${data._id}`);
      }
    }
  }, [data, isLoading, isSuccess, id, history, createNewProject]);

  return (
    <VStack
      spacing={4}
      flex={1}
      bgColor={'shark'}
      height={'90vh'}
      align={'center'}
      justifyContent={'center'}
    >
      <Center w="100px" h="100px">
        <LoadingSpinner
          animationData={spinnerAnimationData}
          width={100}
          height={100}
        />
      </Center>
    </VStack>
  );
};

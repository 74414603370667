import { useItem } from 'dnd-timeline';
import { useState } from 'react';
import { Box, Text } from '@chakra-ui/react';

function TextDisplay(props) {
  // Function to calculate relative luminance
  const getLuminance = (r, g, b) => {
    const [rs, gs, bs] = [r, g, b].map((c) => {
      c = c / 255;
      return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4);
    });
    return 0.2126 * rs + 0.7152 * gs + 0.0722 * bs;
  };

  // Function to calculate contrast ratio
  const getContrastRatio = (color1, color2) => {
    const getRGB = (hex) => {
      const r = parseInt(hex.slice(1, 3), 16);
      const g = parseInt(hex.slice(3, 5), 16);
      const b = parseInt(hex.slice(5, 7), 16);
      return [r, g, b];
    };

    const [r1, g1, b1] = getRGB(color1);
    const [r2, g2, b2] = getRGB(color2);

    const l1 = getLuminance(r1, g1, b1);
    const l2 = getLuminance(r2, g2, b2);

    const ratio = (Math.max(l1, l2) + 0.05) / (Math.min(l1, l2) + 0.05);
    return ratio.toFixed(2) + ':1';
  };

  return (
    <Text
      whiteSpace="nowrap"
      overflow="hidden"
      maxWidth="100%"
      textOverflow={'ellipsis'}
      color={'white'}
      fontSize="sm"
    >
      {props.label}
    </Text>
  );
}

export default TextDisplay;

import { useAuth0 } from '@auth0/auth0-react';
import { Flex, VisuallyHidden, Icon } from '@chakra-ui/react';
import { useFontFamily } from 'api/useFontFamilyApi';
import useRefDimensions from 'hooks/useRefDimensions';
import { useWebFontLoader } from 'hooks/useWebFontLoader';
import { emitCustomEvent } from 'react-custom-events';
import { get, find, isEmpty } from 'lodash';
import { useMeasure } from 'react-use';
import { nanoid } from 'nanoid';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useParams } from 'react-router-dom';
import { useInterval } from 'react-use';
import { ScrubberLayout } from 'scenes/video-scrubber-board';
import { timestamp } from '../../../src/utils/unixtimeStamp';
import { PanelResizer, defaultBgColor } from '../../constants';
import VideoEditingControls from './components/video-editing-controls';
import { PanelGroup, Panel, PanelResizeHandle } from 'react-resizable-panels';
import { MdDragHandle } from 'react-icons/md';
import { ErrorBoundary, PollRemotionExportProgress } from 'components';
import {
  useEditorRepository,
  useSidebarRepository,
  usePollingRepository,
  useUserProjectRepository,
  useTemplateRepository,
  useCanvasZoomRepository,
} from 'repositories';
import { useScrubberData } from 'hooks/useScrubberData';
import { useObservableWithPipe } from 'utils/useObserveable';
import {
  useUserProjectById,
  useUpdateProjectDetails,
} from 'api/useUserProjectApi';
import useScreenSize from 'hooks/useScreenSize';

import './style.css';

// Lazy Component
const EditorArea = React.lazy(() =>
  import('./components/editorarea/EditorArea')
);

// Lazy Show Side Menu Component
const ShowSideMenu = React.lazy(() =>
  import('./components/sidemenus/ShowSideMenu')
);

const CanvasLanding = () => {
  const editorRepo = useEditorRepository();
  const sidebarMenuRepo = useSidebarRepository();
  const pollingRepo = usePollingRepository();
  const userProjectRepo = useUserProjectRepository();
  const templateRepo = useTemplateRepository();
  const canvasZoomRepo = useCanvasZoomRepository();
  const userProjectDataRef = React.useRef(null);
  const screenSizes = useScreenSize();

  const { selectedKeyFrame, sortedScrubberData } = useScrubberData();
  const { uniqueId } = selectedKeyFrame;
  const selectedItem = find(sortedScrubberData, (f) => f.uniqueId === uniqueId);

  const { id } = useParams();
  const mainlayoutRef = React.useRef(null);
  const dimensions = useRefDimensions(mainlayoutRef);
  const [sidebarMeasureRef, { width: sidebarWidth }] = useMeasure();
  // console.log('params', id);

  const { fontsLoaded, isLoading, loadWebFontCallback } = useWebFontLoader();
  /** state */
  const pollingState = useObservableWithPipe(pollingRepo.getObservable());
  const { activeMenu, isOpen } = useObservableWithPipe(
    sidebarMenuRepo.getSidebarObservable()
  );

  const { toggled: isRunning, interval = 5000 } = pollingState;

  const { user } = useAuth0();
  const { data: projectByIdData } = useUserProjectById(id);
  const { mutateAsync } = useUpdateProjectDetails();
  const { data: fontFamilyData } = useFontFamily();

  /** project initial data */
  React.useEffect(() => {
    if (!isEmpty(projectByIdData) && !userProjectDataRef.current) {
      const id = get(projectByIdData, '_id', null);
      editorRepo.remoteFetch(id);
      userProjectDataRef.current = true;
    }
  }, [editorRepo, projectByIdData]);

  React.useEffect(() => {
    const updateReduxWithAuth0 = async () => {
      // const getToken = await getAccessTokenSilently();
      userProjectRepo.update({ ...user, projectId: id });
      // serviceConfig.initConfig({ user: { accessToken: getToken } });
      // get proejct layer details
      // dispatch({
      //   type: sagaActionTypes.GET_PROJECT_LAYER_DETAILS,
      //   payload: { projectId: id },
      // });
      /// projectByIdRefetch(id);
    };
    updateReduxWithAuth0();
  }, [user, userProjectRepo, id]);

  // React.useEffect(() => {
  //   if (title) {
  //     dispatch(
  //       AppActions.userInfoActions.user.add({
  //         projectName: title,
  //       })
  //     );
  //   }
  // }, [dispatch, title]);

  const handleOnClickGrid = (e) => {
    const item = {
      droptype: e.droptype,
      name: e.title,
      type: e.type,
      value: e,
    };
    // console.log('itempropsclick-', item);
    const { value, droptype, ...restItems } = item;
    let startTime = get(item, 'file.start', 0);
    let endTime = get(item, 'file.end', 5000);
    let uniqueId = nanoid(10);
    if (!['videos', 'audios'].includes(droptype)) {
      uniqueId = nanoid(10);
    }
    const itemWithTimeStamp = {
      uniqueId: uniqueId,
      ...restItems,
      ...value,
      droptype: droptype,
      start: item.start || startTime,
      end: item.end || endTime,
      frameStartAt: item.start || startTime,
      frameEndAt: item.end || endTime,
      locked: false,
      visible: true,
      mute: false,
      timestamp: timestamp(),
      isAudio: droptype === 'audios',
      isVideo: droptype === 'videos',
    };

    if (droptype) {
      /** Droptype: Is same as reducer name always */
      editorRepo.add(itemWithTimeStamp);
    }
  };

  const handleOnPanelLayoutChange = (e) => {
    emitCustomEvent(PanelResizer.PANEL_RESIZE);
  };

  const handleOnDragging = (e) => {
    if (!e) {
      emitCustomEvent(PanelResizer.PANEL_RESIZE);
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <VisuallyHidden>
        <svg id="svg" className="hidden" alt="canvas" />
      </VisuallyHidden>
      <ErrorBoundary>
        <Flex flexDir={'column'} flex={1} ref={mainlayoutRef}>
          <PanelGroup direction="vertical" onLayout={handleOnPanelLayoutChange}>
            <Panel
              defaultSize={70}
              maxSize={80}
              onResize={handleOnPanelLayoutChange}
              style={{ display: 'inline-flex' }}
            >
              <Flex
                data-testid="menu+canvas"
                flex={1}
                justifyContent="space-between"
                flexDir={'row'}
                height={'100%'}
              >
                <Flex colSpan={1} rowSpan={5} minH={0} ref={sidebarMeasureRef}>
                  {/** left side area */}
                  <ErrorBoundary>
                    <React.Suspense fallback={<div>Loading...</div>}>
                      <ShowSideMenu
                        activeMenu={activeMenu}
                        dimensions={dimensions}
                        handleOnClickGrid={handleOnClickGrid}
                        isSmallScreen={screenSizes.isSmDown}
                      />
                    </React.Suspense>
                  </ErrorBoundary>
                </Flex>
                <Flex data-testid="flex-editorarea-wrapper" flex={3}>
                  {/** right side video playing */}
                  <React.Suspense fallback={<div>Loading...</div>}>
                    <EditorArea
                      dimensions={dimensions}
                      sidebarWidth={sidebarWidth}
                      isOpen={isOpen}
                    />
                  </React.Suspense>
                </Flex>
              </Flex>
            </Panel>
            <Flex
              as={PanelResizeHandle}
              justify-content="stretch"
              onDragging={handleOnDragging}
            >
              <Flex
                flex={1}
                justifyContent="center"
                justifyItems="center"
                align={'center'}
                position="relative"
              >
                <Icon
                  as={MdDragHandle}
                  color="silverChalice"
                  boxSize={5}
                  position="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                  zIndex={100}
                  title="Drag to resize"
                />
              </Flex>
            </Flex>
            <Panel defaultSize={30} maxSize={50}>
              <ErrorBoundary>
                <Flex
                  data-testid="footer"
                  data-testid2="grid-item-grid-side-menu-controls"
                  bg="woodsmoke2"
                  opacity="0.8"
                  flexDir={'column'}
                  flex={0.3}
                  height={'100%'}
                >
                  {/** bottom controls */}
                  <Flex>
                    <VideoEditingControls />
                  </Flex>
                  <Flex bg="woodsmoke2" opacity="0.8" flex={1}>
                    <ScrubberLayout />
                  </Flex>
                </Flex>
              </ErrorBoundary>
            </Panel>
          </PanelGroup>
        </Flex>
        <PollRemotionExportProgress />
      </ErrorBoundary>
    </DndProvider>
  );
};

export default CanvasLanding;

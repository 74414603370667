/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { useCustomEventListener } from 'react-custom-events';
import PropTypes from 'prop-types';
import Item from './Item';

const defaultDate = new Date('2021-01-01T00:00:00.000Z');

const SortableSubRowItemContext = ({ ...restProps }) => {
  const { item, id } = restProps;
  const [span, setSpan] = React.useState(item.span);

  return (
    <Item {...restProps} span={span}>
      <p data-testid={item.id} data-rowid={item.rowId} />
    </Item>
  );
};

SortableSubRowItemContext.propTypes = {
  key: PropTypes.string.isRequired,
  subrow: PropTypes.arrayOf(PropTypes.any).isRequired,
  id: PropTypes.string.isRequired,
  span: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    duration: PropTypes.number.isRequired,
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
    isSelected: PropTypes.bool.isRequired,
    layerIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    sequenceNumber: PropTypes.number.isRequired,
    thumbnailUrl: PropTypes.string.isRequired,
  }).isRequired,
  rowId: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    span: PropTypes.shape({
      start: PropTypes.number.isRequired,
      end: PropTypes.number.isRequired,
    }).isRequired,
    rowId: PropTypes.string.isRequired,
    layer: PropTypes.shape({
      label: PropTypes.string.isRequired,
    }).isRequired,
    segment: PropTypes.shape({
      id: PropTypes.string.isRequired,
      start: PropTypes.number.isRequired,
      duration: PropTypes.number.isRequired,
      thumbnailUrl: PropTypes.string.isRequired,
    }).isRequired,
    isButton: PropTypes.bool.isRequired,
  }).isRequired,
  setHoveredItem: PropTypes.func.isRequired,
  hoveredItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isHover: PropTypes.bool.isRequired,
    dir: PropTypes.oneOf(['left', 'right']).isRequired,
  }).isRequired,
  showAddon: PropTypes.bool.isRequired,
  setShowAddon: PropTypes.func.isRequired,
};

export default SortableSubRowItemContext;

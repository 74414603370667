import { Flex, Icon, IconButton } from '@chakra-ui/react';
import React from 'react';

const iconButtonWidth = '28px';
const iconButtonHeight = '28px';

const ScrubberRowAction = ({ active, onClick, icon, title }) => {
  return (
    <Flex marginRight="5px">
      <IconButton
        width={iconButtonWidth}
        height={iconButtonHeight}
        backgroundColor={'darkShark'}
        icon={
          <Icon
            as={icon}
            w={20}
            h={18}
            fill={active ? '#00cca7' : '#a8a8a8'}
            color={active ? '#00cca7' : '#a8a8a8'}
          />
        }
        onClick={onClick}
        _hover={{ outline: '#44454f solid 1px' }}
        aria-label="scrubber row action"
        title={title}
      />
    </Flex>
  );
};

export default ScrubberRowAction;

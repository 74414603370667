import { Box } from '@chakra-ui/react';
import Header from 'layouts/shared/header';
import React from 'react';

const Layout = ({ children }) => {
  return (
    <LayoutWrapper>
      <LayoutHeader>
        <Header />
      </LayoutHeader>

      <LayoutMain>{children}</LayoutMain>
    </LayoutWrapper>
  );
};

const LayoutWrapper = ({ children }) => (
  <Box
    width={'100%'}
    height={'100%'}
    backgroundColor={'#1A1C22'}
    color={'white'}
  >
    {children}
  </Box>
);

const LayoutHeader = ({ children }) => (
  <Box width={'100%'} bg="woodsmoke">
    {children}
  </Box>
);

const LayoutMain = ({ children }) => children;

export default Layout;

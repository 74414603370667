import { useAuth0 } from '@auth0/auth0-react';
import { Menu, MenuItem, UserButton } from 'components';
import config from 'config';
import React from 'react';
import { useHistory } from 'react-router-dom';

const menu = [
  {
    title: 'Account Settings',
    path: '/account/settings',
  },
  {
    title: 'Downloads',
    path: '/account/queue',
  },
  {
    title: 'Get Help',
    path: '/',
  },
  {
    title: 'Privacy Policy',
    path: '/privacy',
  },
];

const UserMenu = React.forwardRef(({ baseElement, isOpen, onClick }, ref) => {
  const history = useHistory();

  const { user, logout } = useAuth0();

  return (
    <Menu ref={ref} baseElement={baseElement} isOpen={isOpen}>
      <UserButton size={'md'} {...user} />
      {menu.map((nav, index) => (
        <MenuItem key={index} {...nav} onClick={() => history.push(nav.path)} />
      ))}
      <MenuItem
        onClick={() => logout({ returnTo: 'https://www.clippy.io' })}
        title={'Sign Out'}
        path={''}
      />
    </Menu>
  );
});

export default UserMenu;

import { BehaviorSubject } from 'rxjs';
import { merge, get, findIndex, reduce } from 'lodash';
import produce from 'immer';
import { nanoid } from 'nanoid';

const initialState = {
  clips: [],
  title: 'Eyecast Flex Max Pro',
  width: 1920,
  height: 1080,
  fps: 30,
  userId: '',
  isLoading: false,
  creating: false,
  updating: false,
  removing: false,
  error: '',
  coverPhoto: '',
};

let history = {
  past: [],
  present: initialState,
  future: [],
};

const state = initialState;
export const editorSubject = new BehaviorSubject(state);

export const useEditorObservable = () => {
  /// initialize data
  const initialData = (data) => {
    setNextState({ ...data });
  };

  const loading = (flag) => {
    setNextState({ isLoading: flag });
  };

  const updateHistory = () => {
    const state = editorSubject.getValue();
    history = {
      past: [history.present, ...history.past],
      present: state,
      future: [],
    };
  };

  const update = (payload, recordHistory = true) => {
    const prevState = editorSubject.getValue();
    const { uniqueId } = payload;
    const updatedState = produce(prevState, (draft) => {
      // Find the clip and item to move
      for (const clip of draft.clips) {
        const indexToFind = clip.items.findIndex(
          (item) => item.uniqueId === uniqueId || item.id === uniqueId
        );
        if (indexToFind !== -1) {
          clip.items[indexToFind] = payload;
          break;
        }
      }
    });
    editorSubject.next({ ...updatedState });
    if (recordHistory) updateHistory();
  };

  const bulkUpdate = (payload = []) => {
    payload.forEach((item) => {
      update(item, false);
    });
    updateHistory();
  };

  const updateItemByDeletingGap = (payload) => {
    const { endTime, scrubberRow = NaN, gapDuration = 0 } = payload;
    const floatEndTime = parseFloat(endTime);
    if (isNaN(floatEndTime)) return;
    const prevState = editorSubject.getValue();
    // Find the clip and items in the same scrubberRow and move them to the left by the gapDuration time
    const updatedState = produce(prevState, (draft) => {
      const itemsOfScrubberRow = draft.clips.find(
        (clip) => clip.scrubberRow === scrubberRow
      ).items;

      for (let i = 0; i < itemsOfScrubberRow.length; i++) {
        const item = itemsOfScrubberRow[i];
        if (item.start >= floatEndTime) {
          item.start = Math.max(0, item.start - gapDuration);
          item.end = Math.max(0, item.end - gapDuration);
        }
      }
    });
    editorSubject.next({ ...updatedState });
    updateHistory();
  };

  const updateScrubberRowAndMoveItem = (payload) => {
    const { uniqueId, scrubberRow = NaN } = payload;
    if (isNaN(scrubberRow)) return;
    const prevState = editorSubject.getValue();
    const updatedState = produce(prevState, (draft) => {
      // Find the clip and item to move
      let clipToMove;
      let itemToMove;
      for (const clip of draft.clips) {
        const indexToFind = clip.items.findIndex(
          (item) => item.uniqueId === uniqueId || item.id === uniqueId
        );

        if (indexToFind !== -1) {
          clipToMove = clip;
          itemToMove = clip.items[indexToFind];
          clip.items.splice(indexToFind, 1); // Remove item from the current scrubberRow
          break;
        }
      }

      if (clipToMove && itemToMove) {
        // Find the clip to move the item to
        const newClip = draft.clips.find(
          (clip) => clip.scrubberRow === scrubberRow
        );
        if (newClip) {
          newClip.items.push(itemToMove); // Add item to the new scrubberRow
        } // if not found, create a new clip
        else {
          const newClip = {
            id: nanoid(10),
            duration: itemToMove.duration || 0, // Set the appropriate duration
            scrubberRow, // Set the appropriate scrubberRow
            mute: false, // Set the appropriate values for mute, visible, and locked
            visible: true,
            locked: false,
            items: [itemToMove],
          };
          draft.clips.push(newClip); // Add the new clip with the item
        }
      }
    });
    editorSubject.next({ ...updatedState });
    updateHistory();
  };

  const updateVisible = (payload) => {
    const { clipId, visible } = payload;
    const prevState = editorSubject.getValue();
    const updatedState = produce(prevState, (draft) => {
      // Find the clip and update visible property of the clip object
      const clipToUpdate = draft.clips.find((clip) => clip.id === clipId);
      if (clipToUpdate) {
        clipToUpdate.visible = visible;
      }
    });
    editorSubject.next({ ...updatedState });
    updateHistory();
  };

  const updateLocked = (payload) => {
    const { clipId, locked } = payload;
    const prevState = editorSubject.getValue();
    const updatedState = produce(prevState, (draft) => {
      // Find the clip and update locked property of the clip object
      const clipToUpdate = draft.clips.find((clip) => clip.id === clipId);
      if (clipToUpdate) {
        clipToUpdate.locked = locked;
      }
    });
    editorSubject.next({ ...updatedState });
    updateHistory();
  };

  const updateCoverPhoto = (payload) => {
    const { url } = payload;
    const prevState = editorSubject.getValue();
    const updatedState = produce(prevState, (draft) => {
      // Find the clip and update locked property of the clip object
      draft.coverPhoto = url;
    });
    editorSubject.next({ ...updatedState });
    updateHistory();
  };

  const updateMute = (payload) => {
    const { clipId, mute } = payload;
    const prevState = editorSubject.getValue();
    const updatedState = produce(prevState, (draft) => {
      // Find the clip and update mute property of the clip object
      const clipToUpdate = draft.clips.find((clip) => clip.id === clipId);
      if (clipToUpdate) {
        clipToUpdate.mute = mute;
      }
    });
    editorSubject.next({ ...updatedState });
    updateHistory();
  };

  /** special update for layout size */
  const updateLayoutSize = (payload) => {
    const updatedSize = get(payload, 'size', '1920 X 1080');
    const zoom = updatedSize.split('X');
    const zoomWidth = zoom[0].replace(/\D/g, '');
    const zoomHeight = zoom[1].replace(/\D/g, '');
    const state = editorSubject.getValue();
    const updatedState = produce(state, (draft) => {
      // update the title of the whole draft project
      draft.width = Number(zoomWidth); //;
      draft.height = Number(zoomHeight);
    });

    editorSubject.next({ ...updatedState });
  };

  /** update title */
  const updateTitle = (title) => {
    const prevState = editorSubject.getValue();
    const updatedState = produce(prevState, (draft) => {
      // update the title of the whole draft project
      draft.title = title;
    });
    editorSubject.next({ ...updatedState });
    updateHistory();
  };

  const updateActionItemsTimes = (payload) => {
    const { uniqueId, start, end, scrubberRow = NaN } = payload;
    const prevState = editorSubject.getValue();
    let currentScrubberRow = null;

    const updatedState = produce(prevState, (draft) => {
      // Find the clip and item to move
      const clipToMove = draft.clips.find((clip) =>
        clip.items.some(
          (item) => item.uniqueId === uniqueId || item.id === uniqueId
        )
      );

      if (clipToMove) {
        const itemToMoveIndex = clipToMove.items.findIndex(
          (item) => item.uniqueId === uniqueId || item.id === uniqueId
        );
        // record current scrubberRow
        currentScrubberRow = clipToMove.scrubberRow;

        if (itemToMoveIndex !== -1) {
          const itemToMove = clipToMove.items[itemToMoveIndex];

          // Independently update start and end values
          if (!isNaN(start) && !isNaN(end)) {
            // Store the original values before modification
            const originalStart = itemToMove.start;
            const originalEnd = itemToMove.end;

            // Update start and end
            itemToMove.start = start;
            itemToMove.end = end;

            if (
              itemToMove.droptype === 'audios' ||
              itemToMove.droptype === 'videos'
            ) {
              // Calculate the new timeline duration in milliseconds
              const timelineDuration = end - start;

              // If no frameStartAt was set, initialize it to 0
              if (itemToMove.frameStartAt === undefined) {
                itemToMove.frameStartAt = 0;
              }

              // Calculate the maximum possible duration based on the media file
              const elementMaxDuration = itemToMove.duration * 1000;

              // Calculate the new frameEndAt based on timeline duration
              let newFrameEndAt = itemToMove.frameStartAt + timelineDuration;

              // Make sure we don't exceed the media's actual duration
              if (newFrameEndAt > elementMaxDuration) {
                // Limit frameEndAt to the maximum available duration
                itemToMove.frameEndAt = elementMaxDuration;

                // Also adjust the end time to match the maximum available duration
                itemToMove.end =
                  itemToMove.start +
                  (elementMaxDuration - itemToMove.frameStartAt);
              } else {
                // Set the new frameEndAt
                itemToMove.frameEndAt = newFrameEndAt;
              }

              console.log('Updated media item:', {
                uniqueId: itemToMove.uniqueId,
                start: itemToMove.start,
                end: itemToMove.end,
                frameStartAt: itemToMove.frameStartAt,
                frameEndAt: itemToMove.frameEndAt,
                duration: itemToMove.duration * 1000,
              });
            }
          }
        }
      }
    });

    console.log('update action items times', updatedState);
    editorSubject.next({ ...updatedState });
    updateHistory();

    // Update scrubberRow if provided
    if (!isNaN(scrubberRow) && currentScrubberRow !== scrubberRow) {
      updateScrubberRowAndMoveItem({ uniqueId, scrubberRow });
    }
  };

  const add = (item) => {
    const newItem = { ...item }; // Create a copy of the new item
    const prevState = editorSubject.getValue();
    const updatedState = produce(prevState, (draft) => {
      // Find the clip where you want to add the new item (adjust the logic as needed)
      const targetClip = draft.clips.find(
        (clip) => clip.scrubberRow === item.scrubberRow
      );
      if (targetClip) {
        targetClip.items.push(newItem); // Add the new item to the target clip's items
      } else {
        // Handle the case where the target clip doesn't exist (e.g., create a new clip)
        const newClip = {
          id: nanoid(10),
          duration: item.duration || 0, // Set the appropriate duration
          scrubberRow: 1, // Set the appropriate scrubberRow
          mute: false, // Set the appropriate values for mute, visible, and locked
          visible: true,
          locked: false,
          items: [newItem],
        };
        // Adjust the scrubberRow of existing clips with higher scrubberRow values
        for (let i = 0; i < draft.clips.length; i++) {
          draft.clips[i].scrubberRow++;
        }
        draft.clips.unshift(newClip); // Add the new clip with the item at the beginning
      }
    });
    editorSubject.next({ ...updatedState });
    updateHistory();
  };

  const redo = () => {
    // use first future state as next present ...
    const next = history.future[0];
    // ... and remove from future
    const newFuture = history.future.slice(1);
    history = {
      // push present into past for undo
      past: [history.present, ...history.past],
      present: next || [],
      future: newFuture || [],
    };
    editorSubject.next(next);
  };

  const undo = () => {
    const previous = history.past[0];
    const newPast = history.past.slice(1);
    history = {
      past: newPast || [],
      present: previous || [],
      // push present into future for redo
      future: [history.present, ...history.future],
    };
    editorSubject.next(previous);
  };

  const updating = (flag) => {
    setNextState({ updating: flag });
  };

  const remove = (id) => {
    const prevState = editorSubject.getValue();
    const updatedState = produce(prevState, (draft) => {
      for (const clip of draft.clips) {
        const indexToFind = clip.items.findIndex(
          (item) => item.uniqueId === id
        );

        if (indexToFind !== -1) {
          clip.items = clip.items.filter((item) => item.uniqueId !== id);
          break;
        }
      }
    });
    editorSubject.next({ ...updatedState }); // Update the editorSubject state
    updateHistory();
  };

  const removing = (flag) => {
    setNextState({ removing: flag });
  };

  const error = (message) => {
    setNextState({ error: message });
  };

  const setNextState = (payload) => {
    const state = editorSubject.getValue();
    editorSubject.next(merge({}, state, payload));
  };

  const getObservable = () => {
    return editorSubject;
  };

  const getTotalSeconds = () => {
    const eState = editorSubject.getValue();

    const seconds = reduce(
      eState.clips,
      (acc, clip) => {
        // Filter out items with 'background' droptype
        const relevantItems = clip.items.filter(
          (item) => item.droptype !== 'background'
        );

        if (relevantItems.length > 0) {
          const maxEnd = Math.max(...relevantItems.map((item) => item.end));
          const maxTimeInSeconds = Math.max(acc, maxEnd / 1000);
          return maxTimeInSeconds;
        }

        return acc;
      },
      0
    );

    return seconds;
  };
  const getScrubberMinRowIndex = () => {
    const eState = editorSubject.getValue();
    const minRowIndex = reduce(
      eState.clips,
      (acc, clip) => {
        const minRowPosition = Math.min(acc, clip.scrubberRow || 0);
        return minRowPosition;
      },
      Infinity
    );

    return minRowIndex === Infinity ? 0 : minRowIndex;
  };

  const getScrubberMaxRowIndex = () => {
    const eState = editorSubject.getValue();

    const maxRowIndex = reduce(
      eState.clips,
      (acc, clip) => {
        const maxRowPosition = Math.max(acc, clip.scrubberRow || 0);
        return maxRowPosition;
      },
      0
    );

    return maxRowIndex;
  };

  const getScrubberMinMaxRowIndex = () => {
    const minRow = getScrubberMinRowIndex();
    const maxRow = getScrubberMaxRowIndex();
    return { minRow, maxRow };
  };

  const getLayoutSize = () => {
    const { width, height } = editorSubject.getValue();
    return { width, height };
  };

  const canUndo = () => {
    return history.past.length !== 0;
  };
  const canRedo = () => {
    return history.future.length !== 0;
  };

  return {
    update,
    bulkUpdate,
    updateActionItemsTimes,
    updateLayoutSize,
    add,
    redo,
    undo,
    canUndo,
    canRedo,
    updating,
    remove,
    removing,
    error,
    getObservable,
    getTotalSeconds,
    getScrubberMinMaxRowIndex,
    getScrubberMinRowIndex,
    getScrubberMaxRowIndex,
    getLayoutSize,
    initialData,
    loading,
    updateScrubberRowAndMoveItem,
    updateVisible,
    updateLocked,
    updateMute,
    updateTitle,
    updateCoverPhoto,
    updateItemByDeletingGap,
    editorState: editorSubject,
  };
};

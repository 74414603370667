import React from 'react';
import { useInterval } from 'react-use';
import { usePollingRepository } from 'repositories';
import { useObservableWithPipe } from 'utils/useObserveable';
import isEmpty from 'lodash/isEmpty';
import { useGetRemotionProgress } from 'api/useVideoTranscodeApi';
import { toast } from 'react-toastify';

export const PollRemotionExportProgress = () => {
  const pollingRepo = usePollingRepository();

  /// state
  const pollingState = useObservableWithPipe(pollingRepo.getObservable());
  const { toggled: isRunning, interval = 5000, lambdaInfo = {} } = pollingState;
  const { mutateAsync: getRemotionProgress } = useGetRemotionProgress();

  /** runn the polling for api updates */
  useInterval(
    async () => {
      if (!isEmpty(lambdaInfo)) {
        const progressUpdate = await getRemotionProgress(lambdaInfo);
        const { errors, fatalErrorEncountered } = progressUpdate;
        console.log('progressUpdate', progressUpdate);
        const progress = Math.round(progressUpdate.overallProgress * 100);
        pollingRepo.updateProgress(progress);
        if (progressUpdate.done) {
          pollingRepo.update({
            interval: null,
            toggled: false,
            lambdaInfo: {},
            isInProgress: false,
            progress: 0,
          });
          toast('Video exported. Check queue for completion status.');
        } else if (errors.length > 0 || fatalErrorEncountered) {
          pollingRepo.update({
            interval: null,
            toggled: false,
            lambdaInfo: {},
            isInProgress: false,
            progress: 0,
          });
          toast('Error exporting video. Please try again.');
        } else {
          pollingRepo.updateProgress(progress);
        }
      } else {
        pollingRepo.update({
          interval: null,
          toggled: false,
          lambdaInfo: {},
          isInProgress: false,
          progress: 0,
        });
      }
    },
    isRunning ? interval : null
  );

  return <></>;
};

// usePageTimelineHook.jsx
import { addMinutes } from 'date-fns';
import { nanoid } from 'nanoid';
import React, { useState, useMemo, useCallback } from 'react';
import { orderBy, filter } from 'lodash';
import { useEditorStateRepo, useEditorTotalSeconds } from 'repositories';

import useTimelineScale from 'hooks/useTimelineScale';

const defaultDate = new Date('2021-01-01T00:00:00.000Z');
const DEFAULT_RANGE = {
  start: defaultDate.getTime(),
  end: addMinutes(defaultDate, 30).getTime(),
};

const createTimelineItem = (params) => ({
  id: params.uniqueId || nanoid(10),
  rowId: params.type === 'audios' ? 1 : params.scrubberRow,
  span: {
    start: defaultDate.getTime() + params.startMs,
    end: defaultDate.getTime() + params.endMs,
  },
  disabled: false,
  duration: params.totalMs,
  segmentId: params.segmentId,
  droptype: params.type,
  scrubberRow: params.type === 'audios' ? 1 : params.scrubberRow,
  itemDuration: params.endMs - params.startMs,
  label: params.label,
  spanSeconds: {
    start: params.startMs / 1000,
    end: params.endMs / 1000,
  },
});

const usePageTimelineHook = () => {
  const [range, setRange] = useState(DEFAULT_RANGE);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [isResizing, setIsResizing] = useState(false);

  const { scrubberBarMeta } = useTimelineScale();
  const { scale } = scrubberBarMeta;

  const editorState = useEditorStateRepo();
  const totalSeconds = useEditorTotalSeconds();

  // Memoize range calculation
  const calculatedRange = useMemo(() => {
    const totalMs = totalSeconds * 1000;
    const minTimeMs = 15 * 60 * 1000;
    const maxTimeMs = Math.max(totalMs, minTimeMs);
    const baseTimeMs = 60000;
    const scaledBaseTimeMs = Number((baseTimeMs * scale).toFixed(3));
    const timelineRangeMs = scaledBaseTimeMs + maxTimeMs;

    return {
      start: defaultDate.getTime(),
      end: defaultDate.getTime() + timelineRangeMs,
    };
  }, [totalSeconds, scale]);

  // Memoize rows and items generation
  const { rows, items } = useMemo(() => {
    const rowsMap = new Map();
    const itemsList = [];
    const totalMs = totalSeconds * 1000;
    const firstSegmentId = '1xxx-xxxx-xxxx-xxxx-xxxx';

    // Initialize with first row
    rowsMap.set(9999, {
      id: 9999,
      disabled: false,
      duration: totalMs,
      segmentId: firstSegmentId,
    });

    // Process layers and create items
    const sortedLayers = orderBy(editorState, ['scrubberRow'], ['desc']);

    sortedLayers.forEach((layer) => {
      const scrubberRow = layer.scrubberRow ?? 1;

      // Add row if not exists
      if (!rowsMap.has(scrubberRow)) {
        rowsMap.set(scrubberRow, {
          id: scrubberRow,
          disabled: false,
          duration: totalMs,
          segmentId: firstSegmentId,
        });
      }

      // Create timeline item
      itemsList.push(
        createTimelineItem({
          uniqueId: layer.uniqueId,
          type: layer.droptype,
          scrubberRow,
          startMs: layer.start,
          endMs: layer.end,
          totalMs,
          segmentId: firstSegmentId,
          label:
            layer.label ||
            layer.text ||
            layer.name ||
            layer.textValue ||
            layer.droptype,
        })
      );
    });

    // Add audio row if needed
    const hasAudio = sortedLayers.some((layer) => layer.droptype === 'audios');
    if (hasAudio) {
      rowsMap.set(1, {
        id: 1,
        disabled: false,
        duration: totalMs,
        segmentId: firstSegmentId,
      });
    }

    // Create gap items
    const sortedRows = Array.from(rowsMap.values()).sort((a, b) => b.id - a.id);
    sortedRows.forEach((row) => {
      const rowItems = itemsList
        .filter((item) => item.rowId === row.id)
        .sort((a, b) => a.span.start - b.span.start);

      let lastEnd = defaultDate.getTime();
      rowItems.forEach((item) => {
        if (item.span.start > lastEnd) {
          itemsList.push(
            createTimelineItem({
              type: 'gap',
              scrubberRow: row.id,
              startMs: lastEnd - defaultDate.getTime(),
              endMs: item.span.start - defaultDate.getTime(),
              totalMs: item.span.start - lastEnd,
              segmentId: item.uniqueId || item.id,
              label: 'Gap',
              gapItemId: item.uniqueId,
            })
          );
        }
        lastEnd = item.span.end;
      });
    });

    return {
      rows: sortedRows,
      items: itemsList,
    };
  }, [editorState, totalSeconds]);

  // Update range when calculated range changes
  React.useEffect(() => {
    setRange(calculatedRange);
  }, [calculatedRange]);

  return {
    range,
    setRange,
    items,
    rows,
    totalSeconds,
    hoveredItem,
    setHoveredItem,
    isResizing,
    setIsResizing,
  };
};

export default usePageTimelineHook;

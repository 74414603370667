import { Box, Grid, GridItem } from '@chakra-ui/react';
import { TimelineScrubber } from 'components';
// import useDebounce from 'hooks/useDebounce';
// import useDeepEffect from 'hooks/useDeepEffect';
import useRefDimensions from 'hooks/useRefDimensions';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import inRange from 'lodash/inRange';
import { nanoid } from 'nanoid';
import React from 'react';
import { useDrop } from 'react-dnd';
import { ItemTypes } from '../../../constants/ItemTypes';
import { timestamp } from '../../../utils/unixtimeStamp';
// import useTimelineData from 'hooks/useTimelineScrubber';
import {
  // useSelectedRepository,
  // useEditorRepository,
  // useScrubberBarRepository,
  // usePlayerRepository,
  useSidebarRepository,
} from 'repositories';
import { useObservableWithPipe } from 'utils/useObserveable';
import TimelineWrapper from './TimelineContextWrapper';
import { TimelineElements } from 'components';

const ScrubberLayout = () => {
  // const timelineData = useTimelineData();
  // const editorRepo = useEditorRepository();
  // const selectedRepo = useSelectedRepository();
  const sidebarMenuRepo = useSidebarRepository();
  // const scrubberBarRepo = useScrubberBarRepository();
  // const playerRepo = usePlayerRepository();

  /** EOF states */

  const videoScrubberRef = React.useRef(null);
  const dimensions = useRefDimensions(videoScrubberRef);

  const setActiveMenu = (menuName) => {
    // console.log('SIDE BAR', menuName);
    sidebarMenuRepo.update(menuName);
  };

  return (
    <Grid
      templateRows="repeat(4, 1fr)"
      templateColumns="repeat(1, 1fr)"
      gap={1}
      width={'100%'}
      height={'100%'}
      bg="shark"
      gridAutoFlow="column"
      ref={videoScrubberRef}
    >
      <GridItem
        colSpan={1}
        rowSpan={4}
        minWidth="500px"
        minHeight={'230px'}
        data-testid="video-scrubberr-grid-item"
        gridAutoFlow="column"
      >
        <Box
          display="flex"
          data-testid="timeline-box-wrapper"
          overflow={'auto'}
          css={{
            '&::-webkit-scrollbar': {
              width: '10px',
              height: '10px',
            },
            '&::-webkit-scrollbar-track': {
              width: '1px',
              height: '1px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'var(--chakra-colors-brand-primary-800)',
              borderRadius: '10px',
            },
            '&::-webkit-scrollbar-corner': {
              backgroundColor: 'var(--chakra-colors-brand-primary-800)',
            },
          }}
          {...(dimensions && { ...dimensions })}
        >
          <TimelineWrapper>
            <TimelineElements />
            {/*   <TimelineScrubber
              dimensions={dimensions}
              scrubberData={sortedScrubberData}
              handleOnSelectedKeyFrame={handleOnSelectedKeyFrame}
              handleOnDeselectKeyFrame={handleOnDeselectKeyFrame}
              handleOnLayerLock={handleOnLayerLock}
              handleOnLayerVisible={handleOnLayerVisible}
              handleOnLayerMute={handleOnLayerMute}
              selectedKeyFrame={selectedKeyFrame}
              handleOnScrubberUpdate={handleOnScrubberUpdate}
              handleOnBeforeChange={handleOnBeforeChange}
              handleOnAfterChange={handleOnAfterChange}
              handleOnDeleteAction={handleOnDeleteAction}
              handleOnScrubberRowUpdate={handleOnScrubberRowUpdate}
              totalVideoSeconds={totalVideoSeconds}
              scrubberBarMeta={scrubberBarMeta}
              updateVideoPlayerState={updateVideoPlayerState}
              {...minMaxRowIndex}
            /> */}
          </TimelineWrapper>
        </Box>
      </GridItem>
    </Grid>
  );
};

export default ScrubberLayout;

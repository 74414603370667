import React, { useCallback, useRef, useEffect, memo } from 'react';
import { useTimelineContext } from 'dnd-timeline';
import { Box, Text } from '@chakra-ui/react';

export const TimelineElementsWrapper = memo(({ children, timelineHeight }) => {
  const { setTimelineRef } = useTimelineContext();
  const timelineRef = useRef(null);

  // Use useCallback to create a stable reference function
  const setRef = useCallback(
    (node) => {
      timelineRef.current = node;
      // Only call setTimelineRef when the node actually changes
      if (node !== null) {
        setTimelineRef(node);
      }
    },
    [setTimelineRef]
  );

  return (
    <Box
      ref={setRef}
      maxHeight={`${timelineHeight}px`}
      overflowY="auto"
      overflowX="hidden"
      sx={{
        scrollbarWidth: 'thin',
        scrollbarColor: 'rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.05)',
      }}
      data-testid="card-layout"
      bg="shark"
    >
      {children}
    </Box>
  );
});

// Add display name for debugging purposes
TimelineElementsWrapper.displayName = 'TimelineElementsWrapper';

// Keep the whyDidYouRender config if you're using that library
TimelineElementsWrapper.whyDidYouRender = {
  logOnDifferentValues: true,
  customName: 'TimelineElementsWrapper',
};

export default TimelineElementsWrapper;

import { usePollingObservable } from 'observeables';

export const usePollingRepository = () => {
  const pollingObservable = usePollingObservable();

  const update = async (payload) => {
    try {
      pollingObservable.update(payload);
    } catch (error) {
      pollingObservable.error('Error in updating edit area dimensons');
    }
  };

  const updateProgress = async (progress) => {
    try {
      pollingObservable.updateProgress(progress);
    } catch (error) {
      pollingObservable.error('Error in updating edit area dimensons');
    }
  };

  const getObservable = () => pollingObservable.getObservable();

  return {
    update,
    getObservable,
    updateProgress,
  };
};

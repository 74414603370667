import { ArrowBackIcon } from '@chakra-ui/icons';
import { Button, Flex, Image, Link } from '@chakra-ui/react';
import logo from 'assets/images/clippy-logo.svg';
import React from 'react';
import { Link as ReactLink, useHistory } from 'react-router-dom';
import HeaderUserMenu from './header-user-menu';

const Header = ({ showBack = false }) => {
  const navigate = useHistory();

  return (
    <Flex
      px={[2, 5, 10]}
      h={'4.5rem'}
      alignItems={'center'}
      justifyContent={'space-between'}
      data-testid="header"
      id="canvas-header"
    >
      <Flex>
        <Link as={ReactLink} to={'/dashboard'} data-testid="clippy-logo">
          <Image src={logo} alt={'Clippy'} data-testid="img-clippy-logo" />
        </Link>
        {showBack && (
          <>
            <Button
              ml={3}
              size={'sm'}
              leftIcon={<ArrowBackIcon />}
              colorScheme="teal"
              variant="outline"
              onClick={() => navigate.goBack(-1)}
              data-testid="back-button"
            >
              Back
            </Button>
          </>
        )}
      </Flex>
      <Flex>
        <HeaderUserMenu />
      </Flex>
    </Flex>
  );
};

export default Header;

import React, { useMemo, memo } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useTimelineContext } from 'dnd-timeline';

const TimeAxis = (props) => {
  const { range, direction, sidebarWidth, valueToPixels } =
    useTimelineContext();
  const side = direction === 'rtl' ? 'right' : 'left';

  const { markers, subTicks } = useMemo(() => {
    const sortedMarkers = [...props.markers];
    const delta = sortedMarkers[0].value;
    const startTime = Math.floor(range.start / delta) * delta;
    const endTime = range.end;

    const markers = [];
    const subTicks = [];

    for (let time = startTime; time <= endTime; time += delta) {
      const marker = sortedMarkers[0];
      const relativeTime = time - startTime;
      const label = marker.getLabel(relativeTime);
      const sideDelta = valueToPixels(time - range.start);

      // Add main marker
      markers.push({
        label,
        heightMultiplier: 1,
        sideDelta,
      });

      // Add sub-ticks between markers (if not the last marker)
      if (time + delta <= endTime) {
        const nextSideDelta = valueToPixels(time + delta - range.start);
        const tickInterval = (nextSideDelta - sideDelta) / 10; // Create 9 sub-ticks

        for (let i = 1; i <= 9; i++) {
          subTicks.push({
            sideDelta: sideDelta + tickInterval * i,
            height: i % 2 === 0 ? '8px' : '4px', // Alternate between medium and small ticks
          });
        }
      }
    }

    return { markers, subTicks };
  }, [range, valueToPixels, props.markers]);

  return (
    <Box
      height="30px"
      position="relative"
      overflow="hidden"
      {...(side === 'right'
        ? { marginRight: `${sidebarWidth}px` }
        : { marginLeft: `${sidebarWidth}px` })}
    >
      {/* Render main markers with labels */}
      {markers.map((marker, index) => (
        <div
          key={`marker-${marker.sideDelta}-${index}`}
          style={{
            position: 'absolute',
            top: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            height: '100%',
            [side]: `${marker.sideDelta}px`,
          }}
        >
          <div
            style={{
              height: '8px',
              position: 'absolute',
              top: 0,
            }}
          />
          {marker.label && (
            <Text
              style={{
                fontSize: 12,
                paddingLeft: '3px',
                marginTop: '10px',
              }}
              color={'#A8A8A8'}
            >
              {marker.label}
            </Text>
          )}
        </div>
      ))}

      {/* Render sub-ticks */}
      {subTicks.map((tick, index) => (
        <div
          key={`tick-${tick.sideDelta}-${index}`}
          style={{
            position: 'absolute',
            top: 0,
            height: tick.height,
            width: '1px',
            backgroundColor: '#A8A8A8',
            [side]: `${tick.sideDelta}px`,
          }}
        />
      ))}
    </Box>
  );
};

export default memo(TimeAxis);

import React from 'react';
import { Box, Text } from '@chakra-ui/react';

const DragHandler = ({
  isHover,
  setControlHover,
  dir,
  showAddon = false,
  expanded,
  isButtonElement,
  isAudio = false,
  onClick,
}) => {
  const handleMouseEnter = () => {
    setControlHover(true, dir);
  };

  const handleMouseLeave = () => {
    setControlHover(false, dir);
  };

  const handleResizeDirection = () => {
    // timelinehook.handleSetResizingDirection(dir);
    // onClick();
  };

  return (
    <>
      {dir === 'right' && (
        <Box
          display="none"
          sx={{
            right: 0,
            position: 'absolute',
            top: 0,
            width: '10px',
            height: '100%',
            borderRadius: '2px',
            overflow: 'hidden',
            cursor: 'ew-resize',
            '&::after': {
              display: 'flex',
              position: 'absolute',
              right: 0,
              top: 0,
              margin: 'auto',
              content: '""',
              height: '100%',
              borderWidth: '16px',
              borderStyle: 'solid',
              borderColor: 'transparent',
              borderLeft: '7px solid transparent',
              borderRight: '7px solid rgba(255, 255, 255, 0.1)',
              borderRightColor: 'var(--chakra-colors-tapestry)',
            },
          }}
        >
          <></>
        </Box>
      )}
      {dir === 'left' && (
        <Box
          display="none"
          sx={{
            left: 0,
            position: 'absolute',
            top: 0,
            width: '10px',
            height: '100%',
            overflow: 'hidden',
            borderRadius: '2px',
            cursor: 'ew-resize',
            '&::after': {
              content: '""',
              top: 0,
              height: '100%',
              position: 'absolute',
              margin: 'auto',
              borderWidth: '16px',
              borderStyle: 'solid',
              borderColor: 'transparent',
              borderLeft: '7px solid rgba(255, 255, 255, 0.1)',
              borderRight: '7px solid transparent',
              borderLeftColor: 'var(--chakra-colors-tapestry)',
            },
          }}
        >
          <></>
        </Box>
      )}
    </>
  );
};

export default DragHandler;

import { BehaviorSubject } from 'rxjs';

const initialState = {
  projectId: null,
  email: null,
  name: null,
  projectName: null,
  picture: null,
};

const userProjectSubject = new BehaviorSubject(initialState);

export const useUserProjectObservable = () => {
  const update = (payload) => {
    setNextState({ ...payload, error: '' });
  };

  const updateProjectIds = (payload) => {
    console.log('proeject ids updated', payload);
    setNextState({ ...payload, error: '' });
  };

  const updating = (flag) => {
    setNextState({ updating: flag });
  };

  const removing = (flag) => {
    setNextState({ removing: flag });
  };

  const error = (message) => {
    setNextState({ error: message });
  };

  const setNextState = (payload) => {
    const state = userProjectSubject.getValue();
    userProjectSubject.next({ ...state, ...payload });
  };

  const getObservable = () => {
    return userProjectSubject;
  };

  return {
    update,
    updating,
    removing,
    error,
    getObservable,
    updateProjectIds,
  };
};

import React, {
  useState,
  useContext,
  memo,
  useCallback,
  useRef,
  useEffect,
  useLayoutEffect,
} from 'react';
import { Box } from '@chakra-ui/react';
import { useTimelineContext } from 'dnd-timeline';
import useScrubberHandlers from 'hooks/useScrubberHandlers';

import {
  GsapPixieContext,
  Events,
  emitCustomEvent,
  useCustomEventListener,
} from 'reacthub-pixitweenjs';

function TimeCursor(props) {
  const { totalSeconds, height } = props;
  const timeCursorRef = useRef(null);
  const { playerTimeRef, tl: timeline } = React.useContext(GsapPixieContext);
  const rafRef = React.useRef();
  const seekBarDraggedDurationRef = React.useRef(0);
  const { updateVideoPlayerState } = useScrubberHandlers();

  const { range, direction, sidebarWidth, valueToPixels, pixelsToValue } =
    useTimelineContext();
  const side = direction === 'rtl' ? 'right' : 'left';

  const sliderRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const maxTime = totalSeconds * 1000;

  const updateSlider = useCallback(() => {
    if (isDragging || !timeCursorRef.current || maxTime === 0) {
      return;
    }
    const currentTime = playerTimeRef.current;
    const currentTimeInMs = currentTime * 1000;
    const timeDeltaInPixels = valueToPixels(currentTimeInMs);
    const sideDelta = sidebarWidth + timeDeltaInPixels;
    timeCursorRef.current.style[side] = `${sideDelta}px`;
  }, [isDragging, maxTime, playerTimeRef, valueToPixels, sidebarWidth, side]);

  const handlePause = useCallback(() => {
    emitCustomEvent(Events.SCRUBBER_PAUSE);
  }, []);

  const animate = useCallback(() => {
    updateSlider();
    rafRef.current = requestAnimationFrame(animate);
  }, [updateSlider]);

  useEffect(() => {
    rafRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(rafRef.current);
  }, [animate]);

  const setTimeCallback = useCallback(
    (time) => {
      const maxTime = Math.max(0.001, Number(time));
      const nTotSeconds = totalSeconds && totalSeconds > 0 ? totalSeconds : 1;
      const progress = time / nTotSeconds;
      if (maxTime < playerTimeRef.current) {
        emitCustomEvent(Events.REVERSE_MODE_START, maxTime);
      }
      playerTimeRef.current = maxTime;
      timeline.current && timeline.current.progress(progress);
      updateVideoPlayerState({ play: false, playedSeconds: time });

      // timeline.current && timeline.current.time(maxTime);
      // emitCustomEvent(Events.SCRUBBER_PAUSE);
      // emitCustomEvent(Events.SCRUBBER_SEEK, maxTime);
    },
    [totalSeconds, updateVideoPlayerState]
  );

  const handleDragStart = useCallback(
    (e) => {
      setIsDragging(true);
      handlePause();
      // Pixi Js events
      emitCustomEvent(Events.SEEK_START);
    },
    [handlePause]
  );

  const getDragMoveTime = useCallback(
    (clientX) => {
      if (!sliderRef.current) {
        return 0;
      }

      const rect = sliderRef.current.getBoundingClientRect();
      const clickX = clientX - rect.left;
      const newPosition = Math.max(
        0,
        Math.min(clickX - sidebarWidth, rect.width - sidebarWidth)
      );
      return pixelsToValue(newPosition);
    },
    [sidebarWidth, pixelsToValue]
  );

  const handleDragMove = useCallback(
    (clientX) => {
      if (!sliderRef.current || !isDragging) {
        return;
      }

      const rect = sliderRef.current.getBoundingClientRect();
      const clickX = clientX - rect.left;
      const newPosition = Math.max(
        0,
        Math.min(clickX - sidebarWidth, rect.width - sidebarWidth)
      );
      const newTime = pixelsToValue(newPosition);
      const timeInSec = newTime / 1000;
      setTimeCallback(timeInSec);

      if (timeCursorRef.current) {
        timeCursorRef.current.style[side] = `${sidebarWidth + newPosition}px`;
      }
    },
    [
      totalSeconds,
      isDragging,
      sidebarWidth,
      totalSeconds,
      pixelsToValue,
      setTimeCallback,
      side,
    ]
  );

  const handleDragEnd = useCallback(() => {
    setIsDragging(false);
    const time = playerTimeRef.current;
    const maxTime = Math.max(0.001, Number(time));

    // reset time to its current position when move end.
    const nTotSeconds = totalSeconds && totalSeconds > 0 ? totalSeconds : 1;
    const progress = maxTime / nTotSeconds;

    playerTimeRef.current = maxTime;
    timeline.current && timeline.current.progress(progress);

    timeline.current && timeline.current.time(maxTime);

    // pixijs events
    emitCustomEvent(Events.SCRUBBER_PAUSE);

    emitCustomEvent(Events.SCRUBBER_SEEK, maxTime);
    emitCustomEvent('TIMELINE_SHIFT_END', { time: maxTime, progress });

    emitCustomEvent(Events.SEEK_END);
    emitCustomEvent(Events.REVERSE_MODE_END, maxTime);
  }, [totalSeconds]);

  // Mouse event handlers
  const handleMouseMove = useCallback(
    (e) => handleDragMove(e.clientX),
    [handleDragMove]
  );

  // Touch event handlers
  const handleTouchMove = useCallback(
    (e) => {
      if (e.touches && e.touches[0]) {
        handleDragMove(e.touches[0].clientX);
      }
    },
    [handleDragMove]
  );

  useEffect(() => {
    if (isDragging) {
      // Mouse events
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleDragEnd);

      // Touch events
      document.addEventListener('touchmove', handleTouchMove, {
        passive: false,
      });
      document.addEventListener('touchend', handleDragEnd);
      document.addEventListener('touchcancel', handleDragEnd);
    }

    return () => {
      // Mouse events
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleDragEnd);

      // Touch events
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleDragEnd);
      document.removeEventListener('touchcancel', handleDragEnd);
    };
  }, [isDragging, handleMouseMove, handleTouchMove, handleDragEnd]);

  return (
    <div
      ref={sliderRef}
      style={{ zIndex: 1000, position: 'relative', height: '100%' }}
      data-testid="timeline-marker-cursor"
    >
      <div
        ref={timeCursorRef}
        onMouseDown={handleDragStart}
        onTouchStart={handleDragStart}
        style={{
          height: '100%',
          width: '12px',
          zIndex: 300,
          position: 'absolute',
          opacity: maxTime === 0 ? 0 : 1,
          cursor: 'pointer',
          touchAction: 'none', // Prevents default touch actions
        }}
        data-testid="timeline-cursor"
      >
        <Box
          width="16px"
          height="16px"
          backgroundColor="#00F9CB"
          borderRadius="50%"
          transform="translate(-7px, -0px)"
          data-testid="timeline-cursor-head"
        />
        <div
          style={{
            height: `${height}px`,
            width: '24px',
            transform: 'translateY(-2px) translateX(-10px)',
          }}
          data-testid="timeline-cursor-line"
        >
          <div
            style={{
              height: `${height}px`,
              width: '3px',
              backgroundColor: '#00F9CB',
              transform: 'translateY(-2px) translateX(10px)',
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default memo(TimeCursor);

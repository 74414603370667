import { fromUnixTime, differenceInSeconds, startOfDay } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

export const defaultDate = new Date('2021-01-01T00:00:00.000Z');
export const getStartAndEndSeconds = (span) => {
  // expanded item;
  const startUnixTimeInMillis = span.start;
  const endUnixTimeInMillis = span.end;
  const startUnixTimeInSeconds = startUnixTimeInMillis / 1000;
  const endUnixTimeInSeconds = endUnixTimeInMillis / 1000;
  // Convert Unix time to a JavaScript Date object
  let startDateTime = fromUnixTime(Math.floor(startUnixTimeInSeconds));
  const endDateTime = fromUnixTime(Math.floor(endUnixTimeInSeconds));
  const inNegativeTimeScale = startDateTime < defaultDate;

  // Get the decimal parts with fixed precision
  const startDecimal = Number((startUnixTimeInSeconds % 1).toFixed(3));
  const endDecimal = Number((endUnixTimeInSeconds % 1).toFixed(3));

  const negativeStartDateTime = startDateTime;
  // check if startDateTime is less than defaultDate, then set startDateTime to defaultDate
  if (startDateTime < defaultDate) {
    startDateTime = defaultDate;
  }

  // Ensure the date is treated in UTC
  const startZonedDate = toZonedTime(startDateTime, 'UTC');
  const endZonedDate = toZonedTime(endDateTime, 'UTC');
  const negativeStartZonedDate = toZonedTime(negativeStartDateTime, 'UTC');

  // start seconds since start of the day
  const startOfTheDay = startOfDay(startZonedDate);
  const endOfTheDay = startOfDay(endZonedDate);
  const negativeStartOfTheDay = startOfDay(negativeStartZonedDate);

  // Calculate seconds since start of day with decimal precision
  const startSecondsSinceStartOfTheDay =
    differenceInSeconds(startZonedDate, startOfTheDay) +
    (inNegativeTimeScale ? 0 : startDecimal);
  const endSecondsSinceStartOfTheDay =
    differenceInSeconds(endZonedDate, endOfTheDay) + endDecimal;
  const negativeStartSecondsSinceStartOfTheDay =
    differenceInSeconds(negativeStartZonedDate, negativeStartOfTheDay) +
    startDecimal;

  // Calculate precise difference between times
  const diffSeconds =
    differenceInSeconds(endZonedDate, startZonedDate) +
    (endDecimal - startDecimal);
  const diffSecondsWithNegativeTimeScale =
    differenceInSeconds(endZonedDate, negativeStartZonedDate) +
    (endDecimal - startDecimal);
  return {
    start: startSecondsSinceStartOfTheDay,
    end: endSecondsSinceStartOfTheDay,
    diff: diffSeconds,
    inNegativeTimeScale,
    negativeStart: negativeStartSecondsSinceStartOfTheDay,
    negativeDiff: diffSecondsWithNegativeTimeScale,
  };
};

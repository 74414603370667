import { useEffect, useState } from 'react';
import { editorSubject } from 'observeables/editorObserveable';
import { map, distinctUntilChanged } from 'rxjs';
import isEqual from 'react-fast-compare';
import reduce from 'lodash/reduce';

export const useEditorTotalSeconds = () => {
  const [totalSeconds, setTotalSeconds] = useState(0);

  useEffect(() => {
    const subscription = editorSubject
      .pipe(
        map((eState) => {
          if (!eState?.clips) return 0;

          return reduce(
            eState.clips,
            (acc, clip) => {
              // Exclude items with 'background' droptype
              const relevantItems = clip.items?.filter(
                (item) => item.droptype !== 'background'
              );

              if (relevantItems.length > 0) {
                const maxEnd = Math.max(
                  ...relevantItems.map((item) => item.end || 0)
                );
                return Math.max(acc, maxEnd / 1000); // Convert ms to seconds
              }

              return acc;
            },
            0
          );
        }),
        distinctUntilChanged(isEqual) // Prevent redundant updates
      )
      .subscribe(setTotalSeconds);

    return () => subscription.unsubscribe();
  }, []);

  return totalSeconds;
};

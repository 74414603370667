import { useItem } from 'dnd-timeline';
import { useState } from 'react';
import { Box, Text, IconButton, Flex } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import {
  BsCardImage as ImageIcon,
  BsCameraVideo as VideoIcon,
  BsSoundwave as AudioIcon,
  BsImageAlt as BackgroundIcon,
} from 'react-icons/bs';
import {
  BiText as TextIcon,
  BiSticker as StickerIcon,
  BiSolidFileGif as GifIcon,
} from 'react-icons/bi';
import { RiShapesLine as ShapeIcon } from 'react-icons/ri';
import useScrubberHandlers from 'hooks/useScrubberHandlers';
import DragHandler from './DragHandler';
import TextDisplay from './TextDisplay';

const ITEM_COLORS_ENUM = {
  videos: {
    bg: '#FF4D4D',
    border: '#FF6B6B',
    hover: '#FF3333',
    selection: '#FF1A1A',
    icon: VideoIcon,
  },
  images: {
    bg: '#FFB700',
    border: '#FFC940',
    hover: '#FF9900',
    selection: '#FFD700',
    icon: ImageIcon,
  },
  audios: {
    bg: '#00B4D8',
    border: '#48CAE4',
    hover: '#0096C7',
    selection: '#00E5FF',
    icon: AudioIcon,
  },
  texts: {
    bg: '#10B981',
    border: '#34D399',
    hover: '#059669',
    selection: '#00FF9D',
    icon: TextIcon,
  },
  shapes: {
    bg: '#9333EA',
    border: '#A855F7',
    hover: '#7E22CE',
    selection: '#B24FFF',
    icon: ShapeIcon,
  },
  stickers: {
    bg: '#6366F1',
    border: '#818CF8',
    hover: '#4F46E5',
    selection: '#4834FF',
    icon: StickerIcon,
  },
  backgrounds: {
    bg: '#EC4899',
    border: '#F472B6',
    hover: '#DB2777',
    selection: '#FF1A8C',
    icon: BackgroundIcon,
  },
  transitions: {
    bg: '#F97316',
    border: '#FB923C',
    hover: '#EA580C',
    selection: '#FF5500',
    icon: BackgroundIcon,
  },
  gap: {
    bg: '#374151',
    border: '#4B5563',
    hover: '#1F2937',
    stripe: '#2C3440',
    selection: '#6B7280',
    icon: null,
  },
  titles: {
    bg: '#8B5CF6',
    border: '#A78BFA',
    hover: '#7C3AED',
    selection: '#9333EA',
    icon: TextIcon,
  },
  overlays: {
    bg: '#FBBF24',
    border: '#FCD34D',
    hover: '#F59E0B',
    selection: '#FFD700',
    icon: BackgroundIcon,
  },
  gifs: {
    bg: '#EF4444',
    border: '#F87171',
    hover: '#DC2626',
    selection: '#FF3333',
    icon: GifIcon,
  },
  elements: {
    bg: '#22C55E',
    border: '#4ADE80',
    hover: '#16A34A',
    selection: '#00FF66',
    icon: ShapeIcon,
  },
};

function Item({ id, span, rowId, item, ...restProps }) {
  const [isHovered, setIsHovered] = useState(false);
  const { setNodeRef, attributes, listeners, itemStyle, itemContentStyle } =
    useItem({
      id,
      span,
      data: {
        rowId,
      },
    });

  const { selectedKeyFrame, handleOnSelectedKeyFrame, handleOnDeleteGap } =
    useScrubberHandlers();
  const isSelected = selectedKeyFrame?.uniqueId === id;

  const itemType = item?.droptype;
  const colors = ITEM_COLORS_ENUM[itemType] || ITEM_COLORS_ENUM.gap;
  const isGap = itemType === 'gap';
  const ItemIcon = colors.icon;

  const stripeStyle = isGap
    ? {
        background: `repeating-linear-gradient(
      45deg,
      ${colors.bg},
      ${colors.bg} 10px,
      ${colors.stripe} 10px,
      ${colors.stripe} 20px
    )`,
        opacity: 0.9,
      }
    : {};

  const handleDelete = (e) => {
    const { droptype, scrubberRow, spanSeconds } = item;
    if (droptype === 'gap' && scrubberRow) {
      const { start, end } = spanSeconds;
      const gapDuration = Math.max(0, end - start) * 1000;
      handleOnDeleteGap({
        scrubberRow: Number(scrubberRow),
        endTime: parseFloat(end * 1000),
        gapDuration,
      });
    }
  };

  const handleOnItemSelect = (e) => {
    if (handleOnSelectedKeyFrame) {
      handleOnSelectedKeyFrame(id);
      if (item?.spanSeconds?.start) {
        // handleOnSeekUpdate(item?.spanSeconds?.start || 0);
      }
    }
  };

  return (
    <Box
      ref={setNodeRef}
      style={{
        ...itemStyle,
        ...stripeStyle,
        ...(!isGap && { zIndex: 10 }),
      }}
      {...listeners}
      {...attributes}
      borderColor={isSelected ? colors.selection : colors.border}
      bg={!isGap && (isHovered ? colors.hover : colors.bg)}
      borderWidth={2}
      borderRadius="md"
      boxShadow={isSelected ? 'lg' : isHovered ? 'md' : 'sm'}
      cursor="pointer"
      position="relative"
      {...(isGap && {
        borderStyle: 'dashed',
      })}
      _hover={{
        transform: isSelected ? 'scale(1.02)' : 'scale(1.01)',
      }}
      // as="button"
      // onMouseDown={handleOnItemSelect}
      // onPointerDown={handleOnItemSelect}
      // onTouchStart={handleOnItemSelect}
    >
      <Box
        style={itemContentStyle}
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="full"
        opacity={isSelected ? 1 : 0.9}
      >
        <DragHandler
          dir="left"
          borderColor={isSelected ? colors.selection : colors.border}
        />
        <Box
          px={4}
          py={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          width="100%"
          flexWrap="nowrap"
          position="relative"
        >
          {!isGap && (
            <Flex align="center" justify="center" width="100%">
              {ItemIcon && (
                <Box mr={2} color={isSelected ? colors.selection : 'white'}>
                  <ItemIcon boxSize={4} />
                </Box>
              )}
              <TextDisplay
                label={item?.label}
                color={isSelected ? colors.selection : 'white'}
                fontWeight={isSelected ? 'bold' : 'medium'}
                fontSize="sm"
              />
            </Flex>
          )}
          {isGap && (
            <Box position="absolute" display="flex" alignItems="center">
              <IconButton
                icon={<DeleteIcon size={20} />}
                aria-label="Delete gap"
                size="sm"
                colorScheme="teal"
                variant="ghost"
                data-testid="delete-gap"
                data-action="delete-gap"
                data-id={id}
                id={id}
                onClick={handleDelete}
                onMouseUp={handleDelete}
                onTouchEnd={handleDelete}
              />
            </Box>
          )}
        </Box>
        <DragHandler
          dir="right"
          borderColor={isSelected ? colors.selection : colors.border}
        />
      </Box>
    </Box>
  );
}

export default Item;

import { useScrubberBarRepository } from 'repositories';
import { useObservableWithPipe } from 'utils/useObserveable';

const useTimelineScale = () => {
  const scrubberBarRepo = useScrubberBarRepository();

  const scrubberBarMeta = useObservableWithPipe(
    scrubberBarRepo.getScrubberBarObservable()
  );
  return { scrubberBarMeta };
};

export default useTimelineScale;

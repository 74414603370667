import React, { useCallback, useContext, useRef } from 'react';
import { TimelineContext } from 'dnd-timeline';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import usePageTimelineHook from 'hooks/usePageTimelineHook';
import useScrubberHandlers from 'hooks/useScrubberHandlers';
import {
  GsapPixieContext,
  Events,
  emitCustomEvent,
  useCustomEventListener,
} from 'reacthub-pixitweenjs';

// import { VideoTimeContext } from '../../../../contexts/VideoTimeProvider';
// import usePageTimelineHook from '../../../../hooks/usePageTimelineHook';
// import useCompositionHook from '../../../../hooks/useCompositionHook';
import { getStartAndEndSeconds } from './utils';

const TimelineWrapper = (props) => {
  const { range, items, rows } = usePageTimelineHook();
  // const {
  //   handleUpdateSegment,
  //   handleUpdateDuration,
  //   handleDragElementUpdateDuration,
  // } = useCompositionHook();

  const {
    handleOnScrubberUpdate,
    handleOnSeekUpdate,
    handleOnSelectedKeyFrame,
    handleOnDeleteGap,
  } = useScrubberHandlers();
  // const { throttledSetTimeRef } = useContext(VideoTimeContext);

  const onResizeRef = useRef(false);
  const dragStartedRef = useRef(false);
  const dragStoppedRef = useRef(false);
  const operationStartTimeRef = useRef(null);
  const hasDragMovedRef = useRef(false);
  const hasResizeMovedRef = useRef(false);
  const DRAG_THRESHOLD_MS = 100;
  const RESIZE_THRESHOLD_MS = 300;

  const shouldProcessOperation = () => {
    if (!operationStartTimeRef.current) {
      return false;
    }
    const operationDuration = Date.now() - operationStartTimeRef.current;
    return operationDuration >= DRAG_THRESHOLD_MS;
  };

  const shouldProcessResizeOperation = () => {
    if (!operationStartTimeRef.current) {
      return false;
    }
    const operationDuration = Date.now() - operationStartTimeRef.current;
    return operationDuration >= RESIZE_THRESHOLD_MS;
  };

  const onResizeEnd = React.useCallback(
    (event) => {
      emitCustomEvent('ON_SEGMENT_DRAG_END');
      console.log('onResizeEnd -> event', event);
      if (!hasResizeMovedRef.current || !shouldProcessResizeOperation()) {
        console.log(
          'Resize operation did not meet duration threshold or no movement detected'
        );
        return;
      }

      const updatedSpan =
        event.active.data.current.getSpanFromResizeEvent?.(event);
      if (!updatedSpan) {
        return;
      }

      const activeItemId = event.active.id;
      const { direction } = event;
      const itemsWithoutGaps = items.filter((item) => item.type !== 'gap');
      // find the diff in span and update the span
      const selectedItem = find(itemsWithoutGaps, { id: activeItemId });
      console.log('selected resize end item', selectedItem);
      if (selectedItem) {
        const { start, end, diff, negativeDiff } =
          getStartAndEndSeconds(updatedSpan);

        const isVideoType = selectedItem.type === 'video';
        const params = {
          action: { id: activeItemId },
          start,
          end,
          dropRowId: selectedItem.rowId,
        };

        handleOnScrubberUpdate(params, true);
      }

      // resize false
      onResizeRef.current = false;
      emitCustomEvent('ON_SEGMENT_DRAG_END');
    },
    [items, handleOnScrubberUpdate]
  );

  const onDragStart = React.useCallback(
    (event) => {
      dragStoppedRef.current = false;
      operationStartTimeRef.current = Date.now();
      hasDragMovedRef.current = false;
      emitCustomEvent('ON_SEGMENT_DRAG_START', {
        dragItemId: event.active?.id,
      });
      handleOnSelectedKeyFrame(event.active?.id);
    },
    [items, handleOnSelectedKeyFrame, handleOnDeleteGap]
  );

  const onDragEnd = React.useCallback(
    (event) => {
      dragStoppedRef.current = true;
      emitCustomEvent('ON_SEGMENT_DRAG_END');
      if (
        !hasDragMovedRef.current ||
        !shouldProcessOperation() ||
        hasResizeMovedRef.current
      ) {
        console.log(
          'Drag operation did not meet duration threshold or no movement detected'
        );
        return;
      }

      let activeRowId = event.over?.id;
      if (!activeRowId) {
        // check if y is the negative value
        const y = event.delta.y < -30;
        if (y && !event.over) {
          activeRowId = 9999;
        }
      }

      const updatedSpan =
        event.active.data.current.getSpanFromDragEvent?.(event);
      if (!updatedSpan || !activeRowId) {
        return;
      }
      const activeItemId = event.active.id;
      // remove items with type is gaps;
      const itemsWithoutGaps = items.filter((item) => item.type !== 'gap');
      const { start, end, inNegativeTimeScale } =
        getStartAndEndSeconds(updatedSpan);
      const selectedItem = find(itemsWithoutGaps, { id: activeItemId });
      const dragDirection = event.delta.x > 3.5 ? 'right' : 'left';
      const params = {
        action: { id: activeItemId },
        start,
        end,
        dropRowId: Number(activeRowId),
      };

      emitCustomEvent(Events.SCRUBBER_PAUSE);
      handleOnScrubberUpdate(params, false);
      handleOnSeekUpdate(start);
    },
    [items, handleOnScrubberUpdate, handleOnSeekUpdate]
  );

  const onDragCancel = React.useCallback((event) => {
    hasDragMovedRef.current = false;
    hasResizeMovedRef.current = false;
    operationStartTimeRef.current = null;
    console.log('drag cancel event', event);
  }, []);

  const onResizeStart = useCallback((event) => {
    operationStartTimeRef.current = Date.now();
    hasResizeMovedRef.current = false;
    emitCustomEvent('ON_SEGMENT_DRAG_START', { dragItemId: event.active?.id });
  }, []);

  const onDragMove = useCallback((event) => {
    hasDragMovedRef.current = true;
    hasResizeMovedRef.current = false;
    // emitCustomEvent('ON_SEGMENT_DRAG_MOVE', { dragItemId: event.active?.id });
  }, []);

  const onResizeMove = useCallback((event) => {
    hasResizeMovedRef.current = true;
    emitCustomEvent('ON_SEGMENT_DRAG_MOVE', { dragItemId: event.active?.id });
  }, []);

  const handleDragOver = useCallback((event) => {
    const { active, over, collisions } = event;
    if (!active || !over || !collisions) {
      return;
    }
    if (active.id !== over.id && collisions.length > 1) {
      // const selectedItem = find(items, { id: activeItemId });
    }
  }, []);

  const { children } = props;
  return (
    <TimelineContext
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onResizeStart={onResizeStart}
      onResizeEnd={onResizeEnd}
      onDragCancel={onDragCancel}
      onDragMove={onDragMove}
      onDragOver={handleDragOver}
      range={range}
      onResizeMove={onResizeMove}
    >
      {children}
    </TimelineContext>
  );
};

TimelineWrapper.propTypes = {
  children: PropTypes.node,
};

export default TimelineWrapper;

import { reducerNames } from '../constants';
import { filter, orderBy, reduce, map, flatMap } from 'lodash';
import React, { useMemo } from 'react';
import {
  useEditorRepository,
  useSelectedRepository,
  usePlayerRepository,
  useEditorStateRepo,
  useEditorTotalSeconds,
} from 'repositories';

import {
  useObservableWithPipe,
  useObservableEditItems,
  useObservableForTitle,
} from 'utils/useObserveable';

export const useScrubberData = () => {
  const editorRepository = useEditorRepository();
  const selectedRepo = useSelectedRepository();
  const playerRepo = usePlayerRepository();

  // Memoize the observables
  const editorObservable = useMemo(
    () => editorRepository.getEditorObservable(),
    [editorRepository]
  );

  const editorState = useObservableEditItems(editorObservable);
  const projectTitle = useObservableForTitle(editorObservable);

  const selectedObservable = useMemo(
    () => selectedRepo.getSelectedObservable(),
    [selectedRepo]
  );

  const playerObservable = useMemo(
    () => playerRepo.getObservable(),
    [playerRepo]
  );

  const selectedKeyFrame = useObservableWithPipe(selectedObservable);
  const playerState = useObservableWithPipe(playerObservable);

  const editorStateRepo = useEditorStateRepo();
  const totalVideoSeconds = useEditorTotalSeconds();

  /** state */

  const minMaxRowIndex = useMemo(
    () => editorRepository.getScrubberMinMaxRowIndex(),
    [editorRepository]
  );

  const dimensions = useMemo(
    () => ({
      height: Number(editorState.height),
      width: Number(editorState.width),
    }),
    [editorState.height, editorState.width]
  );

  // Optimize the clips processing
  const sortedClipItems = useMemo(() => {
    if (!editorStateRepo) return [];

    return orderBy(
      editorStateRepo,
      ['scrubberRow', 'startSeconds'],
      ['asc', 'asc']
    );
  }, [editorStateRepo]);

  const totalVideos = useMemo(
    () =>
      reduce(
        editorStateRepo || [],
        (count, clip) =>
          count +
          filter(clip.items, (item) => item.droptype === reducerNames.videos)
            .length,
        0
      ),
    [editorStateRepo]
  );

  return {
    sortedScrubberData: sortedClipItems,
    clips: editorState.clips,
    totalVideos,
    totalVideoSeconds,
    playerState,
    selectedKeyFrame,
    minMaxRowIndex,
    projectTitle,
    width: dimensions.width,
    height: dimensions.height,
  };
};

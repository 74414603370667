import React, { useCallback, useEffect, useRef } from 'react';
import { useObservableWithPipe } from 'utils/useObserveable';
import { useScrubberData } from 'hooks/useScrubberData';
import {
  useSelectedRepository,
  useSidebarRepository,
  useEditorRepository,
  useScrubberBarRepository,
  usePlayerRepository,
} from 'repositories';
import isEmpty from 'lodash/isEmpty';
import inRange from 'lodash/inRange';
import {
  GsapPixieContext,
  Events,
  emitCustomEvent,
  useCustomEventListener,
} from 'reacthub-pixitweenjs';

const useScrubberHandlers = () => {
  const scrubberDataRef = useRef([]);
  const { sortedScrubberData, totalVideoSeconds, minMaxRowIndex } =
    useScrubberData();
  const editorRepo = useEditorRepository();
  const selectedRepo = useSelectedRepository();
  const sidebarMenuRepo = useSidebarRepository();
  const scrubberBarRepo = useScrubberBarRepository();
  const playerRepo = usePlayerRepository();

  const selectedKeyFrame = useObservableWithPipe(
    selectedRepo.getSelectedObservable()
  );
  const scrubberBarMeta = useObservableWithPipe(
    scrubberBarRepo.getScrubberBarObservable()
  );

  const { playerTimeRef, tl: timeline } = React.useContext(GsapPixieContext);
  const rafRef = React.useRef();
  const seekBarDraggedDurationRef = React.useRef(0);

  useEffect(() => {
    scrubberDataRef.current = sortedScrubberData;
  }, [sortedScrubberData]);

  const handleOnScrubberBarChange = useCallback((newPosition) => {
    // setScrubberBarValue(newPosition);
    // // console.log('newposition', newPosition);
  }, []);

  const handleOnBeforeChange = useCallback(
    (newPosition) => {
      let val = newPosition;
      if (val > totalVideoSeconds) val = totalVideoSeconds;
      if (val < 0) val = 0;
      playerRepo.update({ playedSeconds: val, play: false });
      scrubberBarRepo.update({ value: val });
    },
    [playerRepo, scrubberBarRepo, totalVideoSeconds]
  );

  const handleOnAfterChange = useCallback(
    (newPosition) => {
      let val = newPosition;
      if (val > totalVideoSeconds) val = totalVideoSeconds;
      if (val < 0) val = 0;
      playerRepo.update({ playedSeconds: val, play: false });
    },
    [playerRepo, totalVideoSeconds]
  );

  const updateVideoPlayerState = (data) => {
    playerRepo.update(data);
  };

  const handleOnScrubberUpdate = (payload, resize = false) => {
    const {
      action: { id },
      start,
      end,
      dropRowId,
    } = payload;
    const updatedRowId = dropRowId;
    const startMs = start * 1000;
    const endMs = end * 1000;
    const layerValue = scrubberDataRef.current.find((d) => d.uniqueId === id);
    if (isEmpty(layerValue)) return null;

    const {
      start: selectStart,
      end: selectEnd,
      locked,
      scrubberRow,
    } = layerValue;
    const updatedRowPayload = { ...layerValue };
    let rowChanged = false;

    if (!isNaN(updatedRowId) && updatedRowId !== scrubberRow) {
      rowChanged = true;
      updatedRowPayload.scrubberRow = updatedRowId;
    }

    if (locked) return null;
    if (!resize && !rowChanged && startMs === selectStart) return;
    if (!resize && !rowChanged && endMs === selectEnd) return;

    // const checkIfOverlappingExists = scrubberDataRef.current.some((f) => {
    //   const { start, end } = f;
    //   const isRangeOverlappingStartTime =
    //     f.scrubberRow === updatedRowPayload.scrubberRow &&
    //     f.uniqueId !== id &&
    //     inRange(startMs, start, end);
    //   const isRangeOverlappingEndTime =
    //     f.scrubberRow === updatedRowPayload.scrubberRow &&
    //     f.uniqueId !== id &&
    //     inRange(endMs, start, end);
    //   return isRangeOverlappingStartTime || isRangeOverlappingEndTime;
    // });

    // if (checkIfOverlappingExists) return;
    const updatedPayload = { ...updatedRowPayload, start: startMs, end: endMs };
    editorRepo.updateActionItemsTimes(updatedPayload);
  };

  const handleOnSelectedKeyFrame = (id) => {
    const selectedData = scrubberDataRef.current.find((d) => d.uniqueId === id);
    if (isEmpty(selectedData)) return null;
    const { droptype, locked } = selectedData;
    if (locked) return null;
    if (droptype === 'transitions') {
      sidebarMenuRepo.update('Transition');
    } else if (droptype === 'xaudios') {
      sidebarMenuRepo.update('Audio-Editing');
    } else {
      sidebarMenuRepo.update('Edit');
    }
    selectedRepo.select({ id, uniqueId: id, droptype });
  };

  const handleOnDeselectKeyFrame = () => {
    selectedRepo.unSelect();
  };

  const handleOnLayerLock = (payload) => {
    editorRepo.updateLocked(payload);
    handleOnDeselectKeyFrame();
  };

  const handleOnLayerVisible = (payload) => {
    editorRepo.updateVisible(payload);
  };

  const handleOnLayerMute = (payload) => {
    editorRepo.updateMute(payload);
  };

  const handleOnDeleteAction = (uniqueId) => {
    handleOnDeselectKeyFrame();
    editorRepo.remove(uniqueId);
  };

  const handleOnScrubberRowUpdate = (payload) => {
    const { uniqueId, scrubberRow } = payload;
    const layerValue = scrubberDataRef.current.find(
      (d) => d.uniqueId === uniqueId
    );
    if (!isEmpty(layerValue)) {
      const isAnyVisible = scrubberDataRef.current.some(
        (f) => f.scrubberRow === scrubberRow && f.visible === false
      );
      const isAnyLocked = scrubberDataRef.current.some(
        (f) => f.scrubberRow === scrubberRow && f.locked === true
      );
      const isAnyMuted = scrubberDataRef.current.some(
        (f) => f.scrubberRow === scrubberRow && f.mute === true
      );

      const updatedPayload = {
        ...layerValue,
        scrubberRow: scrubberRow || layerValue.scrubberRow,
        visible: isAnyVisible ? false : true,
        locked: isAnyLocked ? true : false,
        mute: isAnyMuted ? true : false,
      };

      // const checkIfOverlappingExists = scrubberDataRef.current.some((f) => {
      //   const { start, end } = f;
      //   const currentStart = updatedPayload.start;
      //   const currentEnd = updatedPayload.end;
      //   const inRangeWithStartTime =
      //     f.scrubberRow === scrubberRow &&
      //     f.uniqueId !== uniqueId &&
      //     inRange(currentStart, start, end);
      //   const inRangeWithEndTime =
      //     f.scrubberRow === scrubberRow &&
      //     f.uniqueId !== uniqueId &&
      //     inRange(currentEnd, start, end);
      //   return inRangeWithStartTime || inRangeWithEndTime;
      // });
      // if (checkIfOverlappingExists) return;
      editorRepo.updateScrubberRow(updatedPayload);
    }
  };

  /**
   * A wrapper function to handle scrubber update
   * Update the timelie progress and playerTimeRef
   * @param {A} time
   */
  const handleOnSeekUpdate = (time) => {
    const maxTime = Math.max(0.001, Number(time));
    playerTimeRef.current = maxTime;
    // timeline.current && timeline.current.progress(progress);
    timeline.current && timeline.current.time(maxTime);
    emitCustomEvent(Events.SCRUBBER_PAUSE);
    // const newsec = Math.min(time + 0.3, totalVideoSeconds);
    // timelineStateRef.current.setTime(newsec);
    emitCustomEvent(Events.SCRUBBER_SEEK, maxTime);
    const nTotSeconds =
      totalVideoSeconds && totalVideoSeconds > 0 ? totalVideoSeconds : 1;
    const progress = time / nTotSeconds;
    playerTimeRef.current = maxTime;
    timeline.current && timeline.current.progress(progress);
    updateVideoPlayerState({ play: false, playedSeconds: time });
  };

  const handleOnDeleteGap = (data) => {
    editorRepo.updateItemByDeletingGap(data);
  };

  return {
    sortedScrubberData,
    totalVideoSeconds,
    minMaxRowIndex,
    selectedKeyFrame,
    scrubberBarMeta,
    handleOnDeleteGap,
    handleOnScrubberBarChange,
    handleOnBeforeChange,
    handleOnAfterChange,
    updateVideoPlayerState,
    handleOnScrubberUpdate,
    handleOnSelectedKeyFrame,
    handleOnDeselectKeyFrame,
    handleOnLayerLock,
    handleOnLayerVisible,
    handleOnLayerMute,
    handleOnDeleteAction,
    handleOnScrubberRowUpdate,
    handleOnSeekUpdate,
  };
};

export default useScrubberHandlers;

import { Box, Text } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { secondsToMilliseconds } from 'date-fns';
import { useMeasure } from 'react-use';
import { groupItemsToSubrows } from 'dnd-timeline';
import isEmpty from 'lodash/isEmpty';
import TimeAxis from './TimeAxis';
import Row from './Row';
import Subrow from './Subrow';
import TimeCursor from './TimeCursor';
import usePageTimelineHook from 'hooks/usePageTimelineHook';
import SortableSubRowItem from './SortableSubRowItem';
import Sidebar from './Sidebar';
import TimelineElementsWrapper from './TimelineElementsWrapper';

const TIME_AXIS_MARKERS = [
  {
    value: secondsToMilliseconds(5), // Set to 1 second intervals (1000ms)
    getLabel: (timestamp) => {
      // Calculate minutes, seconds, and milliseconds directly from timestamp
      const minutes = Math.floor(timestamp / 60000);
      const seconds = Math.floor((timestamp % 60000) / 1000);
      const milliseconds = Math.floor((timestamp % 1000) / 10); // Get only 2 digits of milliseconds
      // Format with padding
      const minutesStr = minutes.toString().padStart(2, '0');
      const secondsStr = seconds.toString().padStart(2, '0');
      const millisecondsStr = milliseconds.toString().padStart(2, '0');
      return `${minutesStr}:${secondsStr}:${millisecondsStr}`;
    },
  },
];

const Timeline = () => {
  const { rows, range, items, totalSeconds } = usePageTimelineHook();

  const [subPanelRef, { height: subPanelHeight }] = useMeasure();

  const groupedSubrows = useMemo(() => {
    const subRows = groupItemsToSubrows(items, range);
    return isEmpty(subRows) ? [] : subRows;
  }, [items, range]);

  const totalRows = rows.length || 1;
  // calculate row height
  const totalRowHeight = totalRows * 35 + 46;
  const timelineHeight = Math.max(130, subPanelHeight - 30);

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
      data-testid="timeline-items"
      ref={subPanelRef}
      bg="shark"
    >
      <Box
        width="45000px"
        height="100%"
        data-testid="timeline-items-innerwrapper"
        bg="shark"
      >
        <TimelineElementsWrapper timelineHeight={timelineHeight}>
          <Box
            display="flex"
            flexDirection="column"
            rowGap="2px"
            width="100%"
            position="relative"
            data-testid="cardinner"
            bg="#2A2A35"
          >
            <TimeCursor height={totalRowHeight} totalSeconds={totalSeconds} />
            <TimeAxis markers={TIME_AXIS_MARKERS} />
            {rows.map((row, idx) => (
              <Row
                id={row.id}
                key={row.id}
                sidebar={<Sidebar id={row.id} row={row} />}
              >
                {groupedSubrows[row.id]?.map((subrow, index) => (
                  <Subrow
                    key={`${subrow.id}-${index}-${idx}`}
                    id={row.id}
                    items={subrow}
                  >
                    {subrow.map((item) => (
                      <SortableSubRowItem
                        key={`${item.id}-${item.span?.start}-${item.span?.end}`}
                        id={item.id}
                        span={item.span}
                        data-testid={item.id}
                        data-rowid={item.rowId}
                        item={item}
                        gapItemId={item.gapItemId}
                        droptype={item.droptype}
                      />
                    ))}
                  </Subrow>
                ))}
              </Row>
            ))}
          </Box>
        </TimelineElementsWrapper>
      </Box>
    </Box>
  );
};

Timeline.whyDidYouRender = {
  logOnDifferentValues: true,
  customName: 'Timeline',
};

export default Timeline;

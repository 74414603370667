import { useUserProjectObservable } from 'observeables';

export const useUserProjectRepository = () => {
  const useProjectObservable = useUserProjectObservable();

  const update = async (payload) => {
    try {
      useProjectObservable.update(payload);
    } catch (error) {
      useProjectObservable.error('Error in updating edit area dimensons');
    }
  };

  const updateProjectIds = async (payload) => {
    try {
      useProjectObservable.updateProjectIds(payload);
    } catch (error) {
      useProjectObservable.error('Error in updating edit area dimensons');
    }
  };

  const getObservable = () => useProjectObservable.getObservable();

  return {
    update,
    getObservable,
    updateProjectIds,
  };
};

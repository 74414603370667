import {
  Box,
  Center,
  Flex,
  HStack,
  Icon,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { Thumbnail } from 'assets/images/thumbnail';
import { Button, SearchInput } from 'components';
import { get, isEmpty, merge } from 'lodash';
import { nanoid } from 'nanoid';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { TfiExport } from 'react-icons/tfi';
import { FaMusic, FaVideo } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { MdOutlineImage } from 'react-icons/md';
import { Hover, FileUploaderSkeleton } from '../../../components';
import {
  AudioDraggableBox,
  VideoDraggableBox,
} from '../../../components/draggable-box';
import { reducerNames } from '../../../constants';
import { timestamp } from '../../../utils/unixtimeStamp';
import UploadMedia from './media/upload-media';
import {
  useGetProjectMediaAssets,
  useCreateProjectMediaAsset,
} from 'api/useProjectMediaAsset';
import { useEditorRepository } from 'repositories';
import { useMediaUpload } from 'hooks/useMediaUpload';
import useScreenSize from 'hooks/useScreenSize';

function Media({ dimensions }) {
  const [tabIndex, setTabIndex] = React.useState(0);
  const { progress, onMediaUpload, error } = useMediaUpload();
  // rrd
  const { id } = useParams();

  // screen sizes
  const screenSizes = useScreenSize();
  const isSmallScreen = screenSizes.isLgDown;

  // oAuth

  // tanstack query
  const { mutateAsync: addProjectMediaAsset } = useCreateProjectMediaAsset(id);
  const { data: projectMediaAssetData } = useGetProjectMediaAssets(id);
  // end tanstack

  const projectMediaAsset = get(projectMediaAssetData, 'data', {});
  const uploadedvideoFiles = get(projectMediaAsset, 'videos', []);
  const uploadedImageFiles = get(projectMediaAsset, 'images', []);
  const uploadedAudioFiles = get(projectMediaAsset, 'audios', []);
  // console.log('mediaasssest', projectMediaAsset);

  /** editor state */
  const editorRepository = useEditorRepository();
  // const editorState = useObservableWithHistory(
  //   editorRepository.getEditorObservable()
  // );

  /** end editor state */

  // console.log('editor state', editorState.state);

  const isDrawerOpen = false;

  const deleteMediaAssets = useCallback(
    (droptype, assetId) => {
      let { videos = [], audios = [], images = [] } = projectMediaAsset;
      if (droptype === 'videos') {
        videos = videos.filter((f) => f.id !== assetId);
      } else if (droptype === 'audios') {
        audios = audios.filter((f) => f.id !== assetId);
      } else if (droptype === 'images') {
        images = images.filter((f) => f.id !== assetId);
      }
      const mergedData = merge(
        {},
        { id: id, projectId: id },
        {
          data: {
            videos,
            audios,
            images,
          },
        }
      );
      addProjectMediaAsset(mergedData);
    },
    [id, addProjectMediaAsset, projectMediaAsset]
  );

  const onVideoDrop = useCallback(
    (acceptedFiles) => {
      onMediaUpload(acceptedFiles);
    },
    [onMediaUpload]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onMediaUpload,
    accept: ['video/*', '.json'],
  });

  const { getRootProps: audioRootProps, getInputProps: audioInputProps } =
    useDropzone({
      onDrop: onMediaUpload,
      accept: 'audio/*',
    });

  const { getRootProps: imageRootProps, getInputProps: imageInputProps } =
    useDropzone({
      onDrop: onMediaUpload,
      accept: '.jpeg, .png, .jpg',
    });

  const handleOnUploadClick = () => {
    // dispatchAPI(Actions.mediaDrawerActions.set(true));
  };

  /**
   * Add video, audio, images
   */
  const handleOnAddItemToScrubber = (e, item) => {
    e.stopPropagation();
    const { id, droptype } = item;
    let selected = {};
    if (droptype === reducerNames.videos) {
      selected = uploadedvideoFiles.find((f) => f.id === id);
    } else if (droptype === reducerNames.images) {
      selected = uploadedImageFiles.find((f) => f.id === id);
    } else if (droptype === reducerNames.audios) {
      selected = uploadedAudioFiles.find((f) => f.id === id);
    }

    if (isEmpty(selected)) return null;

    const endTime = 1000 * Number(get(selected, 'duration', 5000));
    const uniqueId = nanoid(10);

    const itemWithTimeStamp = {
      ...selected,
      uniqueId: uniqueId,
      droptype: droptype,
      start: 0,
      end: droptype === reducerNames.images ? 5000 : endTime,
      frameStartAt: 0,
      frameEndAt: endTime,
      locked: false,
      visible: true,
      mute: false,
      timestamp: timestamp(),
      isAudio: droptype === 'audios',
      isVideo: droptype === 'videos',
      transformation: {
        ...(selected.width && { width: selected.width }),
        ...(selected.height && { height: selected.height }),
      },
    };

    editorRepository.add(itemWithTimeStamp);
  };

  const handleOnClickdelete = (e, item) => {
    e.stopPropagation();
    deleteMediaAssets(item.deletetype, item.id);
  };

  return (
    <Flex flex={1} data-testid="media-element-wrap">
      {!isDrawerOpen && (
        <Flex
          flexDir={isSmallScreen ? 'row' : 'column'}
          p={[1, 1, 1, 2, 2]}
          bg="shark"
          data-testid="media-elements"
          flex={1}
          // maxHeight={height - 255}
          height="100%"
        >
          <Stack spacing={2} mb={4}>
            {false && (
              <Button
                backgroundColor="azure"
                label="Upload Media"
                onClick={handleOnUploadClick}
              />
            )}
            {false && <SearchInput />}
          </Stack>
          <Flex flex={isSmallScreen ? 1 : 0.91}>
            <Tabs
              isFitted
              size="sm"
              bg="shark"
              index={tabIndex}
              onChange={(index) => {
                setTabIndex(index);
              }}
              width="100%"
            >
              <TabList>
                <Tab
                  _selected={{
                    borderColor: 'white',
                    borderBottom: '1px solid white',
                  }}
                  bg="shark"
                  fontSize="small"
                >
                  <Text fontSize="sm" color="white">
                    Video
                  </Text>
                </Tab>
                <Tab
                  _selected={{ borderBottom: '1px solid white' }}
                  fontSize="small"
                  bg="shark"
                >
                  <Text fontSize="sm" color="white">
                    Image
                  </Text>
                </Tab>
                <Tab
                  _selected={{ borderBottom: '1px solid white' }}
                  fontSize="small"
                  bg="shark"
                >
                  <Text fontSize="sm" color="white">
                    Audio
                  </Text>
                </Tab>
                {false && (
                  <Tab
                    _selected={{ borderColor: 'white' }}
                    fontSize="small"
                    bg="shark"
                  >
                    Sequences
                  </Tab>
                )}
              </TabList>

              <TabPanels
                marginTop="2"
                border="1px dashed rgba(0, 249, 203, 0.5)"
                borderRadius="5"
                height="100%"
              >
                <TabPanel
                  bg="darkShark"
                  height="100%"
                  width="100%"
                  boxSizing="border-box"
                  position="relative"
                  display="flex"
                  flexDirection="column"
                  {...(!isEmpty(uploadedvideoFiles) && {
                    padding: '6px 0 0 0',
                  })}
                  {...getRootProps({ className: 'dropzone' })}
                >
                  {/* Main content area that can scroll */}
                  <Box flex="1" overflow="auto" mb={16}>
                    <Flex flexDir={'column'}>
                      {!isEmpty(uploadedvideoFiles) && (
                        <Flex wrap={`wrap`} gap={[4, 4, 4, 4, 2]}>
                          {uploadedvideoFiles.map((file, index) => {
                            return (
                              <React.Fragment
                                key={file.id || file.name || index}
                              >
                                <VideoDraggableBox
                                  width={'120px'}
                                  file={file}
                                  fileType="media"
                                  type={file.type}
                                  droptype="videos"
                                  id={file.id}
                                  src={file.s3Url || file.src}
                                  key={index}
                                >
                                  <Hover
                                    isEnabled={!file.isLoading}
                                    type={'media'}
                                    onClick={(e) => e.stopPropagation()}
                                    addClick={(e) =>
                                      handleOnAddItemToScrubber(e, {
                                        id: file.id,
                                        droptype: reducerNames.videos,
                                      })
                                    }
                                    deleteClick={(e) =>
                                      handleOnClickdelete(e, {
                                        id: file.id,
                                        deletetype: reducerNames.videos,
                                      })
                                    }
                                  >
                                    <Thumbnail
                                      width={'116px'}
                                      height={'71px'}
                                      base64Image={file.thumbUrl}
                                      uniqueId={file.id}
                                    />
                                  </Hover>
                                  {!file.isLoading && (
                                    <Text
                                      fontSize="xx-small"
                                      align="center"
                                      color="pearl"
                                    >
                                      {file.name}
                                    </Text>
                                  )}
                                </VideoDraggableBox>
                              </React.Fragment>
                            );
                          })}
                        </Flex>
                      )}
                      {progress > 0 && (
                        <FileUploaderSkeleton progress={progress} />
                      )}
                    </Flex>
                    <>
                      <input display="hidden" {...getInputProps()} />
                      {isEmpty(uploadedvideoFiles) && (
                        <>
                          <Box mt={`80px`}>
                            <HStack spacing={3} justifyContent="center">
                              <Icon
                                as={FaVideo}
                                w={4}
                                h={4}
                                color="turquoise"
                              />
                              <Icon
                                as={MdOutlineImage}
                                w={4}
                                h={4}
                                color="turquoise"
                              />
                              <Icon
                                as={FaMusic}
                                w={4}
                                h={4}
                                color="turquoise"
                              />
                            </HStack>
                          </Box>
                          <Center px={5} py={1}>
                            <Text as="span" fontSize="xx-small">
                              ... or drag your video clips, images, or double
                              click here to start uploading
                            </Text>
                          </Center>
                        </>
                      )}
                    </>
                  </Box>

                  {/* Fixed button container at the bottom */}
                  <Box
                    position="absolute"
                    bottom={0}
                    left={0}
                    right={0}
                    p={3}
                    bg="darkShark"
                    borderTop="1px solid"
                    borderColor="whiteAlpha.100"
                    display="flex"
                    justifyContent="center"
                    flex={1}
                    width="100%"
                  >
                    <Button
                      leftIcon={<Icon as={TfiExport} />}
                      colorScheme="teal"
                      variant="solid"
                      size="sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        open();
                      }}
                      width="100%"
                    >
                      Upload Files
                    </Button>
                  </Box>
                </TabPanel>
                <TabPanel
                  bg="darkShark"
                  height="100%"
                  width="100%"
                  position="relative"
                  display="flex"
                  flexDirection="column"
                  boxSizing="border-box"
                  {...(!isEmpty(uploadedImageFiles) && {
                    padding: '6px 0 0 0',
                  })}
                  {...imageRootProps({ className: 'dropzone' })}
                >
                  <Box flex="1" overflow="auto" mb={16}>
                    <Flex>
                      {!isEmpty(uploadedImageFiles) && (
                        <Flex wrap={`wrap`} gap={[4, 4, 4, 4, 2]}>
                          {uploadedImageFiles.map((file, index) => {
                            return (
                              <Box key={file.id || file.name || index}>
                                <Hover
                                  type={'media'}
                                  onClick={(e) => e.stopPropagation()}
                                  addClick={(e) =>
                                    handleOnAddItemToScrubber(e, {
                                      id: file.id,
                                      droptype: reducerNames.images,
                                    })
                                  }
                                  deleteClick={(e) =>
                                    handleOnClickdelete(e, {
                                      id: file.id,
                                      deletetype: reducerNames.images,
                                    })
                                  }
                                >
                                  <Thumbnail
                                    width={'116px'}
                                    height={'71px'}
                                    base64Image={file?.thumbUrl}
                                    uniqueId={file.id}
                                  />
                                </Hover>
                                {!file.isLoading && (
                                  <Text
                                    fontSize="xx-small"
                                    align="center"
                                    color="pearl"
                                  >
                                    {file.name}
                                  </Text>
                                )}
                              </Box>
                            );
                          })}
                        </Flex>
                      )}
                      {progress > 0 && (
                        <FileUploaderSkeleton progress={progress} />
                      )}
                    </Flex>
                    <>
                      <input display="hidden" {...imageInputProps()} />
                      {isEmpty(uploadedImageFiles) && (
                        <>
                          <Box mt={`80px`}>
                            <HStack spacing={3} justifyContent="center">
                              <Icon
                                as={FaVideo}
                                w={4}
                                h={4}
                                color="turquoise"
                              />
                              <Icon
                                as={MdOutlineImage}
                                w={4}
                                h={4}
                                color="turquoise"
                              />
                              <Icon
                                as={FaMusic}
                                w={4}
                                h={4}
                                color="turquoise"
                              />
                            </HStack>
                          </Box>
                          <Center px={5} py={1}>
                            <Text as="span" fontSize="xx-small">
                              ... or drag your images, or double click here to
                              start uploading
                            </Text>
                          </Center>
                        </>
                      )}
                    </>
                  </Box>
                  {/* Fixed button container at the bottom */}
                  <Box
                    position="absolute"
                    bottom={0}
                    left={0}
                    right={0}
                    p={3}
                    bg="darkShark"
                    borderTop="1px solid"
                    borderColor="whiteAlpha.100"
                    display="flex"
                    justifyContent="center"
                    flex={1}
                    width="100%"
                  >
                    <Button
                      leftIcon={<Icon as={TfiExport} />}
                      colorScheme="teal"
                      variant="solid"
                      size="sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        open();
                      }}
                      width="100%"
                    >
                      Upload Files
                    </Button>
                  </Box>
                </TabPanel>
                <TabPanel
                  bg="darkShark"
                  height="100%"
                  width="100%"
                  position="relative"
                  display="flex"
                  flexDirection="column"
                  boxSizing="border-box"
                  {...(!isEmpty(uploadedAudioFiles) && {
                    padding: '6px 0 0 0',
                  })}
                  {...audioRootProps({ className: 'audiodropzone' })}
                >
                  <Box flex="1" overflow="auto" mb={16}>
                    <Flex>
                      {!isEmpty(uploadedAudioFiles) && (
                        <Flex wrap={`wrap`} gap={[4, 4, 4, 4, 2]}>
                          {uploadedAudioFiles.map((file) => {
                            return (
                              <AudioDraggableBox
                                width={'120px'}
                                file={file}
                                fileType="media"
                                type="mp3"
                                droptype="audios"
                                id={file.id}
                                key={file.id}
                                src={file.src}
                              >
                                <Hover
                                  type={'media'}
                                  onClick={(e) => e.stopPropagation()}
                                  addClick={(e) =>
                                    handleOnAddItemToScrubber(e, {
                                      id: file.id,
                                      droptype: reducerNames.audios,
                                    })
                                  }
                                  deleteClick={(e) =>
                                    handleOnClickdelete(e, {
                                      id: file.id,
                                      deletetype: reducerNames.audios,
                                    })
                                  }
                                >
                                  <Thumbnail width={'116px'} height={'71px'} />
                                </Hover>
                                {!file.isLoading && (
                                  <Text
                                    fontSize="xx-small"
                                    align="center"
                                    color="pearl"
                                  >
                                    {file.name}
                                  </Text>
                                )}
                              </AudioDraggableBox>
                            );
                          })}
                        </Flex>
                      )}
                      {progress > 0 && (
                        <FileUploaderSkeleton progress={progress} />
                      )}
                    </Flex>
                    <>
                      <input display="hidden" {...audioInputProps()} />
                      {isEmpty(uploadedAudioFiles) && (
                        <>
                          <Box mt={`80px`}>
                            <HStack spacing={3} justifyContent="center">
                              <Icon
                                as={FaVideo}
                                w={4}
                                h={4}
                                color="turquoise"
                              />
                              <Icon
                                as={MdOutlineImage}
                                w={4}
                                h={4}
                                color="turquoise"
                              />
                              <Icon
                                as={FaMusic}
                                w={4}
                                h={4}
                                color="turquoise"
                              />
                            </HStack>
                          </Box>
                          <Center px={5} py={1}>
                            <Text as="span" fontSize="xx-small">
                              ... or drag your audio or double click here to
                              start uploading
                            </Text>
                          </Center>
                        </>
                      )}
                    </>
                  </Box>
                  {/* Fixed button container at the bottom */}
                  <Box
                    position="absolute"
                    bottom={0}
                    left={0}
                    right={0}
                    p={3}
                    bg="darkShark"
                    borderTop="1px solid"
                    borderColor="whiteAlpha.100"
                    display="flex"
                    justifyContent="center"
                    flex={1}
                    width="100%"
                  >
                    <Button
                      leftIcon={<Icon as={TfiExport} />}
                      colorScheme="teal"
                      variant="solid"
                      size="sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        open();
                      }}
                      width="100%"
                    >
                      Upload Files
                    </Button>
                  </Box>
                </TabPanel>
                <TabPanel bg="darkShark" height="100%" boxSizing="border-box">
                  <Text as="span">four!</Text>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Flex>
      )}
      {isDrawerOpen && <UploadMedia onVideoUpload={onVideoDrop} />}
    </Flex>
  );
}

export default Media;

import React, { useLayoutEffect, useState } from 'react';
import { debounceTime, distinctUntilChanged, shareReplay } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import differenceWith from 'lodash/differenceWith';
import isObject from 'lodash/isObject';
import transform from 'lodash/transform';

export function useObservable(stateSubject) {
  const [state, setState] = useState(stateSubject.getValue());
  useLayoutEffect(() => {
    let prevState = state;
    const subscription = stateSubject.pipe().subscribe((currentState) => {
      setState(currentState);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return state;
}

export function useObservableWithDebounce(stateSubject) {
  const [state, setState] = useState(stateSubject.getValue());

  useLayoutEffect(() => {
    let prevState = state;

    const subscription = stateSubject
      .pipe(
        distinctUntilChanged(),
        debounceTime(10),
        shareReplay(1),
        tap((nextState) => {
          prevState = nextState;
        })
      )
      .subscribe((currentState) => {
        setState(currentState);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return state;
}

export function useObservableWithPipe(stateSubject) {
  const [state, setState] = useState(stateSubject.getValue());

  useLayoutEffect(() => {
    const subscription = stateSubject.pipe().subscribe((currentState) => {
      setState(currentState);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return state;
}

export function useObservableForTitle(stateSubject) {
  const [title, setTitle] = useState(stateSubject.getValue()?.title || '');

  useLayoutEffect(() => {
    const subscription = stateSubject
      .pipe(map((state) => state?.title))
      .subscribe((currentTitle) => {
        setTitle(currentTitle);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return title;
}

export function useObservableEditItems(stateSubject) {
  const [state, setState] = useState(stateSubject.getValue());

  useLayoutEffect(() => {
    const subscription = stateSubject
      .pipe(distinctUntilChanged((prev, curr) => isEqual(prev, curr)))
      .subscribe((currentState) => {
        setState(currentState);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return state;
}
